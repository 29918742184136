import { render, staticRenderFns } from "./ParagraphTypeHtml.vue?vue&type=template&id=f0de5a3c&v-if=html%20%26%26%20html.length%20%3E%200&"
import script from "./ParagraphTypeHtml.vue?vue&type=script&lang=js&"
export * from "./ParagraphTypeHtml.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports