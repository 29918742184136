<template>
  <section class="bx-aside">
    <div ref="asideTop"
         class="bx-aside__top">
      <TeaserGroup v-if="countAsideTeasers > 0"
                   teaser-group-type="bx-teaser-container--one-third-width"
                   :teasers="[pageData.metaData.asideTeasers[0]]"
                   :brand="brand" />
      <AdaAdSlot v-if="!blockAds"
                 slot-name="rectangle"
                 :slot-id="1" />
    </div>
    <div class="bx-aside__sticky">
      <TeaserGroup v-if="countAsideTeasers > 1"
                   class="bx-js-prevent-slot-marker"
                   teaser-group-type="bx-teaser-container--one-third-width"
                   :teasers="pageData.metaData.asideTeasers.slice(1, 6)"
                   :brand="brand" />
      <AdaAdSlot v-for="(adSlot, index) in stickyAdSlotObject"
                 v-show="aktiveChunkId === index"
                 :id="`bx-ada-aside-rotation-${index}`"
                 :key="`sticky-ad-slot-${index}`"
                 class="bx-ada-aside-rotation"
                 :class="{
                   'bx-teaser-container': adSlot.slotName === 'teaser',
                   'bx-teaser-container--one-third-width': adSlot.slotName === 'teaser'
                 }"
                 :slot-name="adSlot.slotName"
                 :slot-id="adSlot.slotId" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../stores/config'
import { usePageStore } from '../stores/page'
import { useAdaStore } from '../stores/ada'
import clientOnly from '../mixins/client-only'
import debounce from '../mixins/debounce'
import AdaAdSlot from './ad-slots/AdaAdSlot.vue'
import TeaserGroup from './teaser/TeaserGroup.vue'

const RECALCULATE = true

export default {
  components: {
    AdaAdSlot,
    TeaserGroup
  },
  mixins: [clientOnly, debounce],
  props: {
    brand: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      vw: null,
      aktiveChunkId: 0,
      asideWrapperHeight: null,
      staticContainerHeight: null,
      stickyChunkHeight: 600,
      preloadChunkDistanceMax: 300,
      lastStickyInnerOffsetTop: -1000,
      chunkSwitchScrollDistance: null,
      availableChunkRotationHeight: null,
      maxVisibleChunkId: 0,
      asideWrapper: null,
      asideAdSlots: ['rectangle_1', 'rectangle_2', 'teaser_20', 'rectangle_3', 'teaser_21', 'rectangle_4', 'teaser_22', 'rectangle_5'],
      registeredAdSlots: [],
      stickyTeaser: [],
      countStickyTeaser: 0
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['pageData', 'brandFromStore']),
    ...mapState(useAdaStore, ['blockAds']),
    stickyAdSlotList () {
      let allowedAdSlots = this.asideAdSlots.slice(1)
      // remove native teaser from list if not allowed
      if (this.pageData.metaData?.disallowNativeTeasers || this.pageData.metaData?.advertorial) {
        allowedAdSlots = allowedAdSlots.filter(item => !item.includes('teaser'))
      }
      return allowedAdSlots
    },
    stickyAdSlotObject () {
      return this.blockAds
        ? []
        : this.stickyAdSlotList.map((slot) => {
          const [slotName, slotId] = slot.split('_')
          return {
            slotName,
            slotId: parseInt(slotId)
          }
        })
    },
    countChunks () {
      return this.stickyAdSlotList.length
    },
    countAsideTeasers () {
      return this.pageData?.metaData?.asideTeasers?.length || 0
    }
  },
  mounted () {
    this.vw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (this.vw >= 1000) {
      this.registerAdSlotOnce('rectangle_1')
      this.registerAdSlotOnce('rectangle_2')
      this.initStickyTeaserGroup()
      this.initAsideSticky()
      // initionally check scroll status once at the beginning to ensure,
      // we know about the right scroll position. Maybe the user already scrolled
      // but we have to wait until page is loaded to get the final available height
      window.addEventListener('load', () => this.handleScroll())
      document.addEventListener('scroll', () => this.debounce(() => this.handleScroll(), 100), { passive: true })
    }
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  methods: {
    vue3CompatibleBeforeDestroy () {
      document.removeEventListener('scroll', () => this.debounce(() => this.handleScroll(), 100))
    },
    /**
     * Get References to the sticky teaser elements and count them
     */
    initStickyTeaserGroup () {
      this.stickyTeaser = this.$el.querySelectorAll('.bx-aside__sticky .bx-teaser-container .bx-teaser')
      this.countStickyTeaser = this.stickyTeaser.length
      this.switchStickyTeaserByChunk()
    },

    /**
     * Switch the sticky teaser based to the aktive chunk
     */
    switchStickyTeaserByChunk () {
      // ensure that min and max values of stickyTasers are respected, if the aktiveChunkId is out of bounds
      const setActiveTeaserIndex = Math.max(Math.min(this.aktiveChunkId, this.countStickyTeaser - 1), 0)
      this.stickyTeaser.forEach((teaser, index) => {
        teaser.style.display = index === setActiveTeaserIndex ? 'block' : 'none'
      })
    },

    /**
     * Initialize the sticky aside functionality, check heights, calculate distances and register scroll event
     */
    initAsideSticky (recalculate = !RECALCULATE) {
      this.asideWrapper = document.querySelector('.bx-aside-wrapper')
      this.staticContainerHeight = this.$refs.asideTop.clientHeight
      this.asideWrapperHeight = this.asideWrapper.clientHeight
      this.availableChunkRotationHeight = this.asideWrapperHeight - this.staticContainerHeight
      if (!recalculate) {
        // initial calculation of chunkSwitchScrollDistance and maxVisibleChunkId
        // if there is enough space for all chunks, we will extend the scroll distance to switch the chunks
        this.chunkSwitchScrollDistance = Math.floor(
          this.availableChunkRotationHeight > this.stickyChunkHeight * this.countChunks
            ? this.availableChunkRotationHeight / this.countChunks
            : this.stickyChunkHeight
        )
        this.maxVisibleChunkId = Math.floor(this.availableChunkRotationHeight / this.chunkSwitchScrollDistance) - 1
      } else {
        // if we have to recalculate cause of height changes, we have to prevent, that loaded ad slots wont'be shown to the user
        // so we just change chunkSwitchScrollDistance based on existing initial maxVisibleChunkId
        this.chunkSwitchScrollDistance = Math.floor(this.availableChunkRotationHeight / (this.maxVisibleChunkId + 1))
      }
    },
    /**
     * Register the ad slot for the molten bundle only once
     *
     * @param {string} adSlot
     */
    registerAdSlotOnce (adSlot) {
      if (!this.blockAds && adSlot && !this.registeredAdSlots.includes(adSlot)) {
        // check if molten bundle is already loaded, if not wait for it
        if (window?.MoltenBundle && window?.MoltenBundle?.cmd) {
          this.registeredAdSlots.push(adSlot)
          window.bxMb.adSlots.push(adSlot)
          window.MoltenBundle.cmd.push(() => {
            window.MoltenBundle.push(adSlot)
          })
        } else {
          setTimeout(() => {
            this.registerAdSlotOnce(adSlot)
          }, 10)
        }
      }
    },

    /**
     * Check if the height of the aside wrapper or the static container has changed
     */
    hasHeightChanged () {
      return (this.$refs.asideTop.clientHeight !== this.staticContainerHeight) ||
        (this.asideWrapper.clientHeight !== this.asideWrapperHeight)
    },

    /**
     * Handle the scroll event, calculate the aktive chunk, preload adSlots and switch the sticky teaser
     */
    handleScroll () {
      // recalculate initial values if the height of the aside wrapper or the static container has changed
      if (this.hasHeightChanged()) {
        this.initAsideSticky(RECALCULATE)
      }

      // calculate the offset of the sticky aside - if the chunk gets stuck, the offset gets positive
      // restict the offset to the available space of the availableChunkRotationHeight
      const stickyInnerOffsetTop = Math.min(
        -(this.asideWrapper.offsetTop + this.staticContainerHeight + document.body.scrollTop + document.body.getBoundingClientRect().top),
        this.availableChunkRotationHeight - this.stickyChunkHeight
      )

      // dont calc further than the last chunk, we reached end of aside wrapper and stay at the last chunk
      const calculatedChunk = Math.min(
        Math.floor(stickyInnerOffsetTop / this.chunkSwitchScrollDistance),
        this.countChunks - 1
      )

      // check the scroll direction
      const scrollDirection = stickyInnerOffsetTop - this.lastStickyInnerOffsetTop > 0 ? 'down' : 'up'
      this.lastStickyInnerOffsetTop = stickyInnerOffsetTop

      // preload the ad slot for the next chunk regarding the scroll direction
      if (calculatedChunk >= 0) {
        const preloadAdSlotIndex = scrollDirection === 'down' ? calculatedChunk + 1 : calculatedChunk - 1
        /**
         * Especially with long articles, where there is enough space to display all chunks
         * and the scroll area for switching chunks (chunkSwitchScrollDistance) increases
         * - based on dividing the available height (availableChunkRotationHeight) by the number of chunks -
         * we may preload the rotating ad slots far too early.
         *
         * In the frontend test article, which is far from the reality of live articles, we come up with a factor of over 6,
         * i.e. over 3600px that still have to be scrolled before the ad is actually visible. That could then be considered fraud.
         *
         * So we also have to find out how far away the next chunk is in order to trigger the next ad slot based on a fixed pixel value.
         *
         * To get the position within the active chunk, we calculate stickyInnerOffsetTop (current position within availableChunkRotationHeight)
         * modulo chunkSwitchScrollDistance (the height that each chunk has within the availableChunkRotationHeight)
         * and get the position within the current chunk as the remainder of the integer division.
         *
         * If the user scrolls down, we get directly the distance to the next chuck switch.
         *
         * For the distance in the other scrolling direction (up), we have to subtract the calculated value from the chunkSwitchScrollDistance.
         *
         * At the end, depending on the scrolling direction, we have the absolute value in pixels that remain until the next chunk change.
         */
        const nextChunkDistance = Math.abs((scrollDirection === 'down' ? this.chunkSwitchScrollDistance : 0) - Math.round(Math.abs(stickyInnerOffsetTop % this.chunkSwitchScrollDistance)))
        // and lets check if the next chunkId is not greater than the maxVisibleChunkId and not less than 1 (index 0 is already registered within page load)
        if (nextChunkDistance < this.preloadChunkDistanceMax && preloadAdSlotIndex < this.maxVisibleChunkId && preloadAdSlotIndex >= 1) {
          this.registerAdSlotOnce(this.stickyAdSlotList[preloadAdSlotIndex])
        }
      }

      if (this.aktiveChunkId !== calculatedChunk && calculatedChunk >= 0) {
        // the user made scroll progress und the calculated chunk changed, so we switch the rotationsSlotId
        this.aktiveChunkId = calculatedChunk
        this.switchStickyTeaserByChunk()

        // ensure the ad slot for this chunk is registered,
        // maybe the user scrolled too fast or in wrong direction
        if (!this.registeredAdSlots.includes(this.stickyAdSlotList[this.aktiveChunkId])) {
          this.registerAdSlotOnce(this.stickyAdSlotList[this.aktiveChunkId])
        }
      }
    }
  }
}
</script>
