<template>
  <div>
    <SocialShare v-if="rsConfig.socialSettings.socialShare.horoscope.length && pageData"
                 page-type="horoscope"
                 :page-meta="pageData.pageMeta"
                 :meta-data="pageData.metaData"
                 :headline="pageData.body.headline"
                 :brand="brand" />
    <template v-for="(p, index) in paragraph?.paragraphs">
      <ParagraphTypeInterline v-if="p.paragraphType === 'interline'"
                              v-bind="p.interline"
                              :key="`horoscope-interline-${index}`" />
      <ParagraphTypeHtml v-else-if="p.paragraphType === 'text'"
                         :key="`horoscope-text-${index}`"
                         :html="p.text" />
      <ParagraphTypeQuote v-else-if="p.paragraphType === 'quote'"
                          v-bind="p.quote"
                          :key="`horoscope-quote-${index}`" />
      <ParagraphTypeImage v-else-if="p.paragraphType === 'image'"
                          :key="`horoscope-image-${index}`"
                          :image="p.image"
                          :brand="brand" />
      <ParagraphTypeSlider v-else-if="p.paragraphType === 'slider'"
                           v-bind="p.slider"
                           :key="`horoscope-slider-${index}`"
                           :brand="brand"
                           :meta-data="pageData?.metaData"
                           :include-depth="0"
                           :type-count="1" />
      <ParagraphTypeMoonRun v-else-if="p.paragraphType === 'moonrun'"
                            :key="`horoscope-moonrun-${index}`"
                            :moon-run="p.moonrun" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
import { useConfigStore } from '../../stores/config'
import SocialShare from '../content-footer/SocialShare.vue'
import ParagraphTypeInterline from './ParagraphTypeInterline.vue'
import ParagraphTypeHtml from './ParagraphTypeHtml.vue'
import ParagraphTypeQuote from './ParagraphTypeQuote.vue'
import ParagraphTypeImage from './ParagraphTypeImage.vue'
import ParagraphTypeSlider from './ParagraphTypeSlider.vue'
import ParagraphTypeMoonRun from './ParagraphTypeMoonRun.vue'

export default {
  components: {
    SocialShare,
    ParagraphTypeInterline,
    ParagraphTypeHtml,
    ParagraphTypeQuote,
    ParagraphTypeSlider,
    ParagraphTypeImage,
    ParagraphTypeMoonRun
  },
  props: {
    paragraph: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(usePageStore, ['pageData']),
    ...mapState(useConfigStore, ['rsConfig'])
  }
}
</script>
