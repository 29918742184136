<template>
  <div class="bx-recipe__serving"
       data-tc="recipe-serving">
    <button class="icon icon-minus"
            @click.prevent="handleChange('decrement')">
      <svg class="bx-serving__icon">
        <use href="/images/icon_sprite.svg#minus" />
      </svg>
    </button>
    <input v-model.number="amount"
           type="number"
           :min="min"
           :max="max"
           :title="`Anzahl ${serving?.unit?.name}`"
           :aria-label="`Anzahl ${serving?.unit?.name}`"
           @change="handleChange()">
    <span>{{ serving?.unit?.name }}</span>
    <span v-if="serving?.unit?.measurementPerUnit?.amount && serving?.unit?.measurementPerUnit?.unit">
      (à {{ serving.unit.measurementPerUnit.amount }} {{ serving.unit.measurementPerUnit.unit }})
    </span>
    <button class="icon icon-plus"
            @click.prevent="handleChange('increment')">
      <svg>
        <use href="/images/icon_sprite.svg#plus" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    serving: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      amount: 0,
      min: 1,
      max: 999
    }
  },
  computed: {
    servingRatio () {
      return this.amount / this.serving?.amount
    }
  },
  mounted () {
    this.amount = this.serving?.amount
  },
  methods: {
    handleChange (action) {
      if (action === 'increment') {
        this.amount += 1
      } else if (action === 'decrement') {
        this.amount -= 1
      }

      if (this.amount < this.min) {
        this.amount = this.min
      } else if (this.amount > this.max) {
        this.amount = this.max
      }

      this.$emit('servingChanged', this.servingRatio)
    }
  }
}
</script>
