<template>
  <span />
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import EventBus from '../../event-bus/EventBus'
import deviceDetection from '../../mixins/device-detection'
import urlHelper from '../../mixins/url-helper'

/*
  How do I ensure functionality?
  ------------------------------
  INFOnline Measurement Manager fires a tracking pixel at the URL base.io which should appear in the
  network traffic for the test, stage and prod environments!
*/
export default {
  mixins: [deviceDetection, urlHelper],
  props: ['pageData'],
  head () {
    return {
      link: this.measurementManagerEnabled
        ? [
            { rel: 'preload', as: 'script', href: `https://${this.rsConfig.ivwTags.serviceDomainName}/iomm/latest/manager/base/es6/bundle.js`, id: 'IOMmBundle' },
            { rel: 'preload', as: 'script', href: `https://${this.rsConfig.ivwTags.serviceDomainName}/iomm/latest/bootstrap/loader.js` }
          ]
        : [],
      script: [
        ...this.measurementManagerEnabled
          ? [{
              src: `https://${this.rsConfig.ivwTags.serviceDomainName}/iomm/latest/bootstrap/loader.js`,
              'data-vmid': 'ivw-measurement-manager-script',
              key: 'ivw-measurement-manager-script',
              type: 'text/javascript',
              async: false,
              onload: () => {
                this.configureMeasurementManager()
                this.triggerMeasurementManagerPageview()
              }
            }]
          : []
      ]
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    measurementManagerEnabled () {
      return this.rsConfig.ivwTags.serviceDomainName.length && process.env.NODE_ENV !== 'development'
    }
  },
  mounted () {
    // listen to global event
    EventBus.$on('triggerIVW', this.triggerIVWHandler)
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  methods: {
    vue3CompatibleBeforeDestroy () {
      EventBus.$off('triggerIVW', this.triggerIVWHandler)
    },
    triggerIVWHandler () {
      this.triggerMeasurementManagerPageview()
    },
    getIvwData () {
      const data = this.rsConfig.ivwTags
      const cp = data.cpPrefix + '_' + (this.pageData && this.pageData.metaData ? this.pageData.metaData.IVWTag : '')
      return this.hasMobileAds
        ? { ...data.mobile, cp }
        : this.isHome()
          ? { ...data.desktop.home, cp }
          : { ...data.desktop.rest, cp }
    },
    configureMeasurementManager () {
      if (this.measurementManagerEnabled) {
        const ivwData = this.getIvwData()
        window.IOMm?.('configure', { // eslint-disable-line
          st: ivwData.st,
          dn: this.rsConfig.ivwTags.serviceDomainName,
          dg: ['development', 'testing'].includes(process.env.NODE_ENV), // debug flag
          mh: 5
        })
      }
    },
    triggerMeasurementManagerPageview () {
      if (this.measurementManagerEnabled) {
        const ivwData = this.getIvwData()
        window.IOMm?.('pageview', { cp: ivwData.cp }) // eslint-disable-line
      }
    }
  }
}
</script>
