<template>
  <div id="apprs"
       class="bx-app-wrapper">
    <App :brand="brand"
         :url="url"
         :authorization="authorization"
         :consent-config="consentConfig"
         :ad-size-prediction="adSizePrediction"
         :config="config" />
  </div>
</template>

<script>

// We want to export the pure App without the mounting logic for RSv2.
// Therefore, we have to wrap it in this new wrapper component for RSv1.
import App from './App.vue'

export default {
  components: {
    App
  },
  props: {
    brand: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    authorization: {
      type: String,
      default: null
    },
    consentConfig: {
      type: Object,
      default: () => {}
    },
    adSizePrediction: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  }
}

</script>
