import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useSessionStore } from './session'
import { useConfigStore } from './config'
import {
  getConfig
} from './utils/helpers'
import {
  addBillingAddress as addBillingAddressOrcApi,
  addCard as addCardOrcApi,
  addDefaultPaymentMethod as addDefaultPaymentMethodOrcApi,
  addSubscription as addSubscriptionOrcApi,
  deletePaymentMethod as deletePaymentMethodOrcApi,
  deleteSubscription as deleteSubscriptionOrcApi,
  getBillingAddress as getBillingAddressOrcApi,
  getPaymentMethods as getPaymentMethodsOrcApi,
  getSubscription as getSubscriptionOrcApi,
  getSubscriptionProduct as getSubscriptionProductOrcApi,
  confirmSubscription as confirmSubscriptionOrcApi
} from './utils/orc-api'

export const usePaymentStore = defineStore('payment', () => {
  // Stores
  const sessionStore = useSessionStore()
  const configStore = useConfigStore()

  // State
  const paymentMethods = ref([])
  const statusMessage = ref(null)
  const billingAddress = ref(null)
  const subscription = ref(null)

  // Actions

  const storeStatusMessage = (newStatusMessage) => {
    statusMessage.value = newStatusMessage
  }

  const storeBillingAddress = (newBillingAddress) => {
    billingAddress.value = newBillingAddress
  }

  const storeSubscription = (newSubscription) => {
    subscription.value = newSubscription
  }

  const refreshPaymentMethods = async () => {
    try {
      paymentMethods.value = await getPaymentMethodsOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    } catch (e) {
      console.error(e)
    }
  }

  const deletePaymentMethod = async (paymentMethodId) => {
    try {
      await deletePaymentMethodOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token), paymentMethodId)
      await refreshPaymentMethods()
    } catch (e) {
      console.error(e)
    }
  }

  const getSubscription = async () => {
    const subscription = await getSubscriptionOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    storeSubscription(subscription.data)
    return subscription.data
  }

  const addSubscription = async () => {
    const subscription = await addSubscriptionOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    storeSubscription(subscription.data)
  }

  const confirmSubscription = async () => {
    await confirmSubscriptionOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
  }

  const deleteSubscription = async () => {
    const message = await deleteSubscriptionOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    storeStatusMessage({ message })
    return message.data
  }

  // Adds a new card and updates the paymentMethods in the state
  const addCard = async (cardToken) => {
    await addCardOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token), { cardToken })
    await refreshPaymentMethods()
  }

  const getSubscriptionProduct = async () => {
    const product = await getSubscriptionProductOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    return product.data
  }

  const getBillingAddress = async () => {
    const address = await getBillingAddressOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token))
    storeBillingAddress(address.data)
  }

  const addBillingAddress = async (address) => {
    return addBillingAddressOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token), address)
  }

  const addDefaultPaymentMethod = async (token) => {
    return addDefaultPaymentMethodOrcApi(getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token), token.id)
  }

  return {
    paymentMethods,
    billingAddress,
    subscription,
    refreshPaymentMethods,
    deletePaymentMethod,
    getSubscription,
    addSubscription,
    confirmSubscription,
    deleteSubscription,
    addCard,
    getSubscriptionProduct,
    getBillingAddress,
    addBillingAddress,
    addDefaultPaymentMethod
  }
})
