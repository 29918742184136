export default {
  data () {
    return {
      blockEntries: 3
    }
  },
  methods: {
    numBlockEntries () {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      return viewportWidth < 480 ? 3 : viewportWidth < 600 ? 5 : 7
    },
    setBlockEntriesBasedOnViewport (num, active) {
      const blockEntries = this.numBlockEntries()
      if (blockEntries === 3) return
      this.blockEntries = blockEntries
      this.paginationItems = this.createPaginationItems(num, active, blockEntries)
    },
    createPaginationItems (num, active, blockEntries = 3) {
      // isAmp is from pageStore and imported by component that uses this mixin
      const seoCenterPage = Math.floor(num / 2)

      const renderList = []
      let blockStart = (active - ((blockEntries - 1) / 2)) > 0 ? (active - ((blockEntries - 1) / 2)) : 1
      let blockEnd = blockStart + blockEntries - 1

      if (blockEnd > num) {
        blockEnd = num
        blockStart = (blockEnd - blockEntries + 1) > 0 ? blockEnd - blockEntries + 1 : 1
      }

      if (blockStart > 1) renderList.push(1)

      for (let i = blockStart; i <= blockEnd; i++) {
        renderList.push(i)
      }

      if (active === 1 && seoCenterPage > (blockEnd + 1)) {
        renderList.push(seoCenterPage)
      }

      if (blockEnd < num) renderList.push(num)
      return renderList
    },
    addItemStyleClass (current, active, num) {
      let setStyle = 'bx-pager__item'
      const blockEntries = this.blockEntries
      const blockStart = (active - ((blockEntries - 1) / 2)) > 0 ? (active - ((blockEntries - 1) / 2)) : 1
      const blockEnd = blockStart + blockEntries - 1
      const seoCenterPage = Math.floor(num / 2)

      if (current === active) setStyle += ' bx-pager__item--active'

      if (num > blockEntries) {
        if (current === 1 && blockStart > 2) setStyle += ' bx-pager__item--dots-after'
        if (current === blockStart && blockStart > 2) setStyle += ' bx-pager__item--dots-before'
        if (current === seoCenterPage && active === 1 && (seoCenterPage > (blockEnd + 1))) setStyle += ' bx-pager__item--dots-before'
        if (current === num && blockEnd < (num - 1)) setStyle += ' bx-pager__item--dots-before'
      }

      return setStyle
    },
    ampAddItemStyleClass (current, active, num) {
      const blockEntries = 3
      const seoCenterPage = Math.floor(num / 2)

      let setStyle = ''
      let blockStart = (active - ((blockEntries - 1) / 2)) > 0 ? (active - ((blockEntries - 1) / 2)) : 1
      let blockEnd = blockStart + blockEntries - 1

      if (blockEnd > num) {
        blockEnd = num
        blockStart = blockEnd - blockEntries + 1
      }

      if (current === active) setStyle += 'xa-pager__active'

      if (num > blockEntries) {
        if (current === blockStart && blockStart > 2) setStyle += 'xa-pager__dotted'
        if (current === seoCenterPage && active === 1 && (seoCenterPage > (blockEnd + 1))) setStyle += 'xa-pager__dotted'
        if (current === num && blockEnd < (num - 1)) setStyle += 'xa-pager__dotted'
      }

      return setStyle
    }
  }
}
