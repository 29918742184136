<template>
  <ul :class="productProsAndConsStyles"
      data-tc="product-pros-and-cons">
    <li v-if="prosAndCons?.pros?.length">
      <span v-if="titlePros"
            class="bx-product__title bx-product__title--pros-and-cons">{{ titlePros }}</span>
      <ul class="bx-product__pros">
        <li v-for="(pro, proIndex) in displayedPros"
            :key="proIndex"
            data-tc="product-pro"
            v-html="pro" />
      </ul>
    </li>
    <li v-if="prosAndCons?.cons?.length">
      <span v-if="titleCons"
            class="bx-product__title bx-product__title--pros-and-cons">{{ titleCons }}</span>
      <ul class="bx-product__cons">
        <li v-for="(con, conIndex) in displayedCons"
            :key="conIndex"
            data-tc="product-con"
            v-html="con" />
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    prosAndCons: {
      type: Object,
      default: () => {}
    },
    titlePros: {
      type: String,
      default: () => ''
    },
    titleCons: {
      type: String,
      default: () => ''
    },
    productGroupType: {
      type: String,
      default: () => ''
    },
    displayMaxItems: {
      type: Number,
      default: () => null
    }
  },
  computed: {
    productProsAndConsStyles () {
      return {
        'bx-product__pros-and-cons': true,
        'bx-content__continuous-text': true,
        'bx-typo--content-continuous-text': true,
        'bx-product__pros-and-cons--single-row': !(this.prosAndCons?.pros && this.prosAndCons?.cons) && this.productGroupType === 'single'
      }
    },
    displayedPros () {
      return this.displayMaxItems ? this.prosAndCons?.pros?.slice(0, this.displayMaxItems) : this.prosAndCons?.pros
    },
    displayedCons () {
      return this.displayMaxItems ? this.prosAndCons?.cons?.slice(0, this.displayMaxItems) : this.prosAndCons?.cons
    }
  }
}
</script>
