import Vue from 'vue'
import { createHead } from '@unhead/vue'
import { UnheadPlugin } from '@unhead/vue/vue2'
import VS2 from 'vue-script2'
import { PiniaVuePlugin } from 'pinia'

import { createStore } from '../../stores'
import App from './AppWrapper.vue'
import { registerStripeComponents } from './../../plugins/stripe-components'
import Paragraphs from '../../components/Paragraphs.vue'

Vue.use(UnheadPlugin)
Vue.use(VS2)
// Needed for Vue2
Vue.use(PiniaVuePlugin)
Vue.config.ignoredElements = [/^amp-/]
registerStripeComponents(Vue)
Vue.component('Paragraphs', Paragraphs)

const isDev = process.env.NODE_ENV === 'development'
const fallbackBrand = process.env.FALLBACK_BRAND

// export a factory function for creating fresh app, router and store
// instances
export function createApp (props) {
  const pinia = createStore(props)
  const head = createHead()
  const app = new Vue({
    // the root instance simply renders the App component.
    pinia,
    unhead: head,
    render: h => {
      if (isDev) {
        const cssBrand = pinia?.state?.value?.page?.brandFromStore ?? fallbackBrand
        try {
          require('../../assets/brands/' + cssBrand + '/development.scss')
        } catch (e) {
          require('../../assets/brands/' + fallbackBrand + '/development.scss')
        }
      }
      return h(App, { props })
    }
  })

  return { app, store: pinia, head }
}
