<template>
  <h1 :id="setSeedingHeadlineId()"
      class="bx-content bx-content__headline bx-typo--content-headline"
      v-html="text" />
</template>

<script>

export default {
  props: {
    // Headline text
    text: {
      type: String,
      default: ''
    },
    metaData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    setSeedingHeadlineId () {
      return (this.metaData.seeding && this.metaData.seeding.enabled && this.metaData.seeding.headlineId.length > 0) ? this.metaData.seeding.headlineId : null // falsy value removes the attribute
    }
  }
}
</script>
