<template>
  <ConsentActions v-slot="{ revokeVendor }">
    <slot v-bind="{ revokeVendor }"
          name="revoke"
    >
      <div class="bx-typo--placeholder">
        <a href="#"
           class="bx-placeholder__revoke-consent"
           @click.prevent="revokeConsent(revokeVendor)">
          Externe Inhalte von {{ embedName }} deaktivieren.
        </a>
      </div>
    </slot>
  </ConsentActions>
</template>

<script>
import ConsentActions from '../consent/ConsentActions.vue'

export default {
  components: { ConsentActions },
  props: {
    embedName: {
      type: String,
      default: 'diesem Anbieter'
    },
    vendorId: {
      type: String,
      required: true
    }
  },
  methods: {
    revokeConsent (revokeVendor) {
      if (this.vendorId) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'custom_tracking_embed_deactivate'
        })
        return revokeVendor(this.vendorId)
      }
    }
  }
}
</script>
