import { render, staticRenderFns } from "./ParagraphTypeFaq.vue?vue&type=template&id=e72de35e&v-if=items%3F.length&"
import script from "./ParagraphTypeFaq.vue?vue&type=script&lang=js&"
export * from "./ParagraphTypeFaq.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports