import * as vue from 'vue'
import { mapState } from 'pinia'

import { useAdaStore } from '../stores/ada'
import AdaSlotMarker from '../components/ad-slots/AdaSlotMarker.vue'

export default {
  data () {
    return {
      markers: []
    }
  },
  computed: {
    ...mapState(useAdaStore, ['blockAds'])
  },
  mounted () {
    if (!this.blockAds) {
      if (this.$options.name === 'ParagraphTypeHtml') {
        this.injectMarkerToHtmlParagraph()
      } else if (this.$options.name === 'TeaserGroup') {
        this.injectMarkerToTeaserGroup()
      }
    }
  },
  methods: {
    createAdaSlotMarker (child, index) {
      let marker = null
      const appConfig = {
        render: () => vue.h(AdaSlotMarker, {
          props: {
            aboveParagraph: {
              index,
              type: 'html',
              nested: true,
              wordcount: child.innerText.split(' ').length
            }
          },
          on: {
            remove: () => {
              this.removeAdaSlotMarker(marker)
            }
          },
          onRemove: () => {
            this.removeAdaSlotMarker(marker)
          }
        })
      }
      if (vue.version.startsWith('2')) {
        // eslint-disable-next-line new-cap
        marker = new vue.default(appConfig).$mount()
      } else {
        // Vue 3 Syntax
        const vue3 = vue // reassigning to avoid bundle warnings in Vue 2
        marker = vue3.createApp(appConfig).mount(document.createElement('div'))
      }

      this.markers.push(marker)
      return marker
    },
    removeAdaSlotMarker (marker) {
      const index = this.markers.indexOf(marker)
      if (index > -1) {
        this.markers.splice(index, 1)
        marker.unmount()
      }
    },
    injectMarkerToHtmlParagraph () {
      this.$el.querySelectorAll(':scope > *').forEach((child, index) => {
        if (child.nextSibling) {
          const marker = this.createAdaSlotMarker(child, index)
          this.$el.insertBefore(marker.$el, child.nextSibling)
        }
      })
    },
    injectMarkerToTeaserGroup () {
      // restrict to certain teaser group types - for AW we need this injection mostly for zodiac teaser
      // otherwise we also hijack teaser groups on overview pages where we don't want them
      const allowedTeaserGroupTypes = ['bx-teaser-container--info', 'bx-teaser-container--recipe', 'bx-teaser-container--lexicon']
      if (allowedTeaserGroupTypes.includes(this.teaserGroupType)) {
        this.injectMarkerToTeaserContainer()
      }
    },
    getTeaserPerRow () {
      let counted = 0
      let referenceTop = null
      const teaserList = Array.from(this.$el.querySelectorAll('.bx-teaser'))
      if (teaserList?.length > 0) {
        // we only need to itterate through the teasers until we find a new row
        // to prevent unnecessary loops, we use for instead of forEach
        for (let i = 0; i < teaserList.length; i++) {
          const teaserTop = teaserList[i].getBoundingClientRect().top
          if (!referenceTop || teaserTop === referenceTop) {
            counted++
            referenceTop = teaserTop
          } else {
            break
          }
        }
      }
      return counted
    },
    injectMarkerToTeaserContainer () {
      const teaserWrapper = this.$el.querySelector('.bx-teaser-container:not(.bx-js-prevent-slot-marker):not(.bx-teaser-container--dialog) .bx-teaser-container__wrapper')
      this.$el.querySelectorAll(`.bx-teaser-container:not(.bx-js-prevent-slot-marker):not(.bx-teaser-container--dialog) .bx-teaser:nth-child(${this.getTeaserPerRow()}n)`).forEach((child, index) => {
        if (child.nextSibling) {
          const marker = this.createAdaSlotMarker(child, index)
          teaserWrapper.insertBefore(marker.$el, child.nextSibling)
        }
      })
    }
  }
}
