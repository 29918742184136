import {
  StripeElementCard,
  StripeElementPayment
} from '@vue-stripe/vue-stripe'

const registerStripeComponents = (app) => {
  // vue2 (RSv1) passes the global Vue instance as an argument
  // vue3 (RSv2) passes the app instance as an argument
  app.component('StripeElementCard', StripeElementCard)
  app.component('StripeElementPayment', StripeElementPayment)
}

export {
  registerStripeComponents
}
