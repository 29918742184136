import { loadStripe } from '@stripe/stripe-js/pure'

import { useConfigStore } from '../stores/config'
import { useSessionStore } from '../stores/session'
import { getConfig } from '../stores/utils/helpers'
import { createPaymentIntent, createPaymentIntentForStoredCard, createSetupIntent } from '../stores/utils/orc-api'
import { logError } from './error'

let instance: StripeApi

export class StripeApi {
  static async getInstance (store) {
    if (import.meta.env.SSR) {
      return null
    }

    if (instance) {
      return instance
    }

    try {
      const configStore = useConfigStore()
      const _stripe = await loadStripe(configStore.rsConfig.stripePublicKey)
      instance = new StripeApi(store, _stripe)

      return instance
    } catch (e) {
      logError(store, 'Error while loading Stripe API', e)
    }
  }

  constructor (store, _stripe) {
    this.store = store
    this.configStore = useConfigStore()
    this.sessionStore = useSessionStore()
    this._stripe = _stripe
  }

  async payWithStoredCard (amount, paymentMethodId) {
    const intent = await createPaymentIntentForStoredCard(getConfig(this.configStore.rsConfig.orchestrationServiceUrl, this.sessionStore.token), { amount, paymentMethodId })

    if (intent.status === 'succeeded') {
      return intent
    }

    if (intent.status === 'requires_action') {
      console.warn('Stored card needs additional authorization')

      // if requires_action, need additional client side 3DS verification
      return await this.confirmCardPayment(intent.client_secret)
    }

    logError(this.store, `Intent returned with invalid status "${intent.status}"`, intent)
    throw new Error(`Intent returned with invalid status "${intent.status}"`)
  }

  async mountPaymentElement (amount, elementId, shouldSetupCardForFutureUsage) {
    const intent = await createPaymentIntent(getConfig(this.configStore.rsConfig.orchestrationServiceUrl, this.sessionStore.token), { amount, shouldSetupCardForFutureUsage })

    await new Promise(resolve => {
      this.elements = this._stripe.elements({
        appearance: {
          theme: 'stripe',

          variables: {
            colorBackground: '#ffffff',
            colorText: '#1d2a4b',
            colorTextPlaceholder: 'rgba(97, 97, 98, 0.6)',
            colorDanger: '#ee5656',
            fontSizeBase: '15.5px',
            spacingGridRow: '24px',
            focusOutline: 'none',
            focusBoxShadow: 'none'
          },

          rules: {
            '.Input': {
              padding: '10.8px 10px',
              border: '1px solid #dedede',
              color: '#4c5b84',
              boxShadow: 'none'
            },
            '.Input:focus': {
              borderColor: '#ab5f6f'
            },
            '.Label': {
              margin: '0 0 8px',
              fontSize: '15.5px'
            }
          }
        },
        clientSecret: intent.client_secret
      })
      this.paymentElement = this.elements.create('payment', {/* TODO: {fields:'prefillUserDetails'} */ })
      this.paymentElement.on('ready', resolve)
      this.paymentElement.mount(elementId)
    })
  }

  unmountPaymentElement () {
    if (this.paymentElement) {
      console.debug('Unmounting payment element')
      this.paymentElement.unmount()
    }
  }

  async confirmCardPayment (clientSecret) {
    const { paymentIntent, error } = await this._stripe.confirmCardPayment(clientSecret)

    if (error) {
      logError(this.store, 'Error on confirming payment intent with stored card', error)
      throw error
    }

    if (paymentIntent.status !== 'succeeded') {
      logError(this.store, 'Could not successfully confirm payment intent with stored card', null, paymentIntent)
      throw new Error('Could not successfully confirm payment intent with stored card')
    }
    console.log('intent', paymentIntent)
    return paymentIntent
  }

  async confirmPayment () {
    if (!this.elements) {
      throw new Error('Stripe Elements not initialised')
    }

    const { paymentIntent, error } = await this._stripe.confirmPayment({
      elements: this.elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.toString()
      }
    })

    if (error) {
      logError(this.store, 'Error on confirming payment intent', error)
      throw new Error('Error on confirming payment intent')
    }

    if (paymentIntent.status !== 'succeeded') {
      logError(this.store, 'PaymentIntent was not successful', null, paymentIntent)
      throw new Error('PaymentIntent was not successful')
    }
  }

  async mountSetupElement (elementId) {
    const intent = await createSetupIntent(getConfig(this.configStore.rsConfig.orchestrationServiceUrl, this.sessionStore.token))
    const stripeInstance = await this._stripe

    this.elements = stripeInstance.elements({

      appearance: {
        theme: 'stripe',

        variables: {
          colorBackground: '#ffffff',
          colorText: '#1d2a4b',
          colorTextPlaceholder: 'rgba(97, 97, 98, 0.6)',
          colorDanger: '#ee5656',
          fontSizeBase: '15.5px',
          spacingGridRow: '24px',
          focusOutline: 'none',
          focusBoxShadow: 'none'
        },

        rules: {
          '.Input': {
            padding: '10.8px 10px',
            border: '1px solid #dedede',
            color: '#4c5b84',
            boxShadow: 'none'
          },
          '.Input:focus': {
            borderColor: '#ab5f6f'
          },
          '.Label': {
            margin: '0 0 8px',
            fontSize: '15.5px'
          }
        }
      },
      clientSecret: intent.client_secret
    })
    this.paymentElement = this.elements.create('payment', {/* TODO: {fields:'prefillUserDetails'} */ })
    this.paymentElement.mount(elementId)
  }

  async confirmSetup () {
    if (!this.elements) {
      logError(this.store, 'Stripe Elements not initialised')
      return
    }

    const { setupIntent, error } = await this._stripe.confirmSetup({
      elements: this.elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.toString()
      }
    })

    if (error) {
      logError(this.store, 'Error on setupIntent', error, setupIntent)
      throw error
    }

    if (setupIntent.status !== 'succeeded') {
      logError(this.store, 'Error while confirming setupIntent', null, setupIntent)
      throw new Error('Error while confirming setupIntent')
    }

    return setupIntent
  }

  getCallbackClientSetupSecret () {
    return new URLSearchParams(window.location.search).get('setup_intent_client_secret')
  }

  getCallbackClientSecret () {
    return new URLSearchParams(window.location.search).get('payment_intent_client_secret')
  }

  isPaymentConfirmCallback () {
    return this.getCallbackClientSecret() !== null
  }

  isSetupConfirmCallback () {
    return this.getCallbackClientSetupSecret()
  }

  getConfirmCallbackType () {
    if (this.isSetupConfirmCallback()) {
      return 'setup'
    }

    if (this.isPaymentConfirmCallback()) {
      return 'payment'
    }

    return null
  }

  async getPaymentIntentByClientSecret () {
    try {
      const clientSecret = this.getCallbackClientSecret()
      if (clientSecret) {
        const { paymentIntent } = await this._stripe.retrievePaymentIntent(clientSecret)
        return paymentIntent
      }

      const clientSetupSecret = this.getCallbackClientSetupSecret()
      if (clientSetupSecret) {
        const { setupIntent } = await this._stripe.retrieveSetupIntent(clientSetupSecret)
        return setupIntent
      }
    } catch (e) {
      logError(this.store, 'Error while creating payment intent', e)
    }
  }

  async getSetupIntentByClientSecret () {
    try {
      const clientSetupSecret = this.getCallbackClientSetupSecret()
      const { setupIntent } = await this._stripe.retrieveSetupIntent(clientSetupSecret)
      return setupIntent
    } catch (e) {
      logError(this.store, 'Error while creating payment intent', e)
    }
  }
}
