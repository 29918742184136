<template>
  <div class="bx-product__other-partners"
       data-tc="product-other-partners">
    <span v-if="title"
          class="bx-product__title">{{ title }}</span>
    <ul>
      <template v-for="(partner, i) in otherPartners">
        <li v-if="partner.url && partner.partner"
            :key="`partner-${i}`"
            class="bx-product__partner">
          <a :href="partner.url"
             target="_blank"
             data-tc="product-other-partner-url">
            <span data-tc="product-other-partner-partner">{{ partner.partner }}</span>
            <div class="bx-product__button">Zum Produkt</div>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: () => null
    },
    otherPartners: {
      type: Array,
      default: () => []
    }
  }
}
</script>
