<template>
  <form class="bx-form bx-form--pur bx-typo--form"
        autocomplete="on"
        @submit.prevent="submit">
    <h2 class="bx-typo--content-interline-h2">
      Passwort vergessen?
    </h2>
    <div class="bx-typo--content-continuous-text">
      <p>
        Trage hier deine E-Mail-Adresse ein, um einen Link für dein neues Passwort anzufordern.
      </p>
    </div>
    <div :class="{ 'bx-has-error': error }">
      <label for="reset_email"
             class="bx-form__label">
        E-Mail-Adresse
      </label>
      <input id="email"
             v-model="email"
             name="reset_email"
             type="text"
             autocomplete="email"
             placeholder="Deine E-Mail-Adresse"
             @focusout="error = null">
      <span v-if="error"
            class="bx-form__message bx-form__message--error">
        {{ error }}
      </span>
    </div>
    <div class="bx-form__item bx-form__item--centered">
      <button type="submit"
              class="bx-form__button"
              :disabled="processing || preventSubmit"
              :data-loader="processing">
        Link anfordern
      </button>
    </div>
  </form>
</template>
<script>
import { mapActions } from 'pinia'

import { isEmail } from '../../../utils/strings.ts'
import { useSessionStore } from '../../../stores/session'

export default {
  props: {
    onSuccess: { type: Function, required: true }
  },
  data () {
    return {
      preventSubmit: true,
      showError: false,
      error: null,
      email: '',
      processing: false
    }
  },
  mounted () {
    // ssr: disable form submit until vue is initialized
    this.preventSubmit = false
  },
  methods: {
    ...mapActions(useSessionStore, ['resetPassword']),
    async submit () {
      this.error = null
      if (this.email.length === 0) {
        this.error = 'Bitte trage deine E-Mail-Adresse ein.'
        return
      }
      if (!isEmail(this.email)) {
        this.error = 'Bitte trage eine gültige E-Mail-Adresse ein.'
        return
      }

      this.error = null
      this.processing = true

      try {
        this.showError = false
        await this.resetPassword({ email: this.email })
        this.onSuccess()
      } catch (error) {
        this.error = 'Bitte trage eine gültige E-Mail-Adresse ein.'
      }

      this.processing = false
    }
  }
}
</script>
