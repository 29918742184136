export default {
  methods: { // image is our std image object
    isEnabled (brand) {
      if (!brand) {
        return null
      }
      return this.rsConfig.imageSrcDefinitions
        ? this.rsConfig.imageSrcDefinitions.isEnabled
        : true
    },
    getCropping (obj, srcSetDefinition) {
      if (!obj) {
        return undefined
      }
      if (obj.crop) { // std image object
        return obj.crop
      }
      if (obj.croppings && srcSetDefinition?.format) {
        return obj.croppings[srcSetDefinition.format] // e.g. get the cropping "landscape" from a teaser
      }
    },
    getCroppedImgSize (image) {
      if (!image) {
        return {}
      }
      if (!image.crop && image.width && image.height) {
        return image
      } else if (image.crop) {
        return {
          width: Math.abs(Math.floor(image.width / 100 * (Number(image.crop.x2) - Number(image.crop.x1)))),
          height: Math.abs(Math.floor(image.height / 100 * (Number(image.crop.y2) - Number(image.crop.y1))))
        }
      } else {
        return {}
      }
    },
    getImageExtension (mimetype, expectedType) {
      if (mimetype === 'image/gif' && expectedType !== 'webp') {
        return '.gif' // webp works for gifs
      } else if (expectedType?.length > 0) {
        return `.${expectedType}`
      } else {
        return '.jpeg' // fallback
      }
    },
    getImgDefinitions (brand, setName) {
      if (!this.rsConfig.imageSrcDefinitions) {
        console.error('no image src definitions for brand \'' + brand + '\'')
        return
      }
      let definitions = this.rsConfig.imageSrcDefinitions.definitions.defaults
      if (setName) {
        if (this.rsConfig.imageSrcDefinitions.definitions[setName]) {
          definitions = this.rsConfig.imageSrcDefinitions.definitions[setName]
        } else {
          console.log(`[WARN] there is not set with name ${setName} - falling back to default`)
        }
      }
      return definitions
    },
    createImageUrl (image, width, cropping, brand, type) {
      const imageServiceUrl = this.rsConfig.imageServiceUrl + '/'
      // conversion is done by suffixing with the destination type, e.g. '.webp'
      return imageServiceUrl +
        (image?.seoName?.length > 0 ? image.seoName : 'image') +
        ',id=' + image?.imageId +
        // workaround until whitelabel gets branded and provides data on gui, cs, etc
        ',b=' + ((brand === 'whitelabel' || ((brand === 'autozeitung' || brand === 'tvmovie') && process.env.NODE_ENV !== 'production')) ? 'maennersache' : brand) +
        ',w=' + width +
        this.createCroppingUrlParams(cropping) +
        ',rm=sk' + // rm=resizeMode, sk=Only Shrink Larger Images (shrink). Another commonly used option is to restrict IM so that it will only shrink images to fit into the size given. Never enlarge!
        this.getImageExtension(image?.mimetype, type)
    },
    createCroppingUrlParams (crop) {
      if (crop && crop.x1 && crop.y1 && crop.x2 && crop.y2) {
        return ',ca=' + crop.x1 + ',' + crop.y1 + ',' + crop.x2 + ',' + crop.y2
      } else {
        return ''
      }
    },
    getImageFormat (image) {
      if (image && image.width && image.height) {
        // TODO make sure that there are not croppings defined in unit px
        if (image.crop &&
          image.crop.x1 !== undefined &&
          image.crop.y1 !== undefined &&
          image.crop.x2 !== undefined &&
          image.crop.y2 !== undefined) {
          const widthFromCropping = (image.width * parseFloat(image.crop.x2)) / 100 - (image.width * parseFloat(image.crop.x1)) / 100
          const heightFromCropping = (image.height * parseFloat(image.crop.y2)) / 100 - (image.height * parseFloat(image.crop.y1)) / 100
          return this.resolutionToFormat(widthFromCropping, heightFromCropping)
        } else {
          return this.resolutionToFormat(image.width, image.height)
        }
      }
    },
    resolutionToFormat (width, height) {
      if (width > height) {
        return 'landscape'
      } else if (width < height) {
        return 'portrait'
      } else {
        return 'square'
      }
    },
    createImgSrc ({ image, brand, setName, type, width, srcSetDefinition }) {
      if (!image || !image.imageId || !brand) {
        return null
      }
      return this.createImageUrl(image, width || this.getImgDefinitions(brand, setName)?.maxWidth, this.getCropping(image, srcSetDefinition), brand, type)
    }, // imgObj may be a standard image or a teaser
    // TODO Refactor createImgSrcSet and createImgSrcSetArray and reduce them to one function
    // the createImgSrcSet format is needed for the slider module > Ticket NEXT-788
    createImgSrcSet (image, brand, setName, type) {
      if (!this.isEnabled(brand)) {
        return ''
      }

      const definitions = this.getImgDefinitions(brand, setName)

      if (!definitions) {
        return ''
      }
      const srcSets = definitions.srcSet

      if (!srcSets) {
        return ''
      }

      const result = []

      for (let i = 0; i < srcSets.length; i++) {
        result.push(this.createImageUrl(image, srcSets[i].width, this.getCropping(image, srcSets[i]), brand, type) + ' ' + srcSets[i].width + 'w')
      }
      return result.join(', ')
    },
    createImgSrcSetArray (image, brand, setName, type) {
      if (!this.isEnabled(brand)) {
        return []
      }

      const definitions = this.getImgDefinitions(brand, setName)

      if (!definitions) {
        return []
      }
      const srcSets = definitions.srcSet

      if (!srcSets) {
        return []
      }

      const result = []

      for (let i = 0; i < srcSets.length; i++) {
        const singleSrcSet = {
          src: this.createImageUrl(image, srcSets[i].width, this.getCropping(image, srcSets[i]), brand, type),
          viewportWidth: srcSets[i].viewportWidth
        }
        result.push(singleSrcSet)
      }

      return result
    },
    createVideoThumbnailSrc ({ image, brand, setName, type, width }) {
      if (!image || !image.imageId || !brand) {
        return null
      }
      return this.rsConfig.imageServiceUrl + '/' +
        'image,id=' + image.imageId +
        ',b=' + brand +
        ',w=' + (width || this.getImgDefinitions(brand, setName).maxWidth) +
        ',rm=sk' +
        this.getImageExtension(image.mimetype, type)
    },
    getImageAltText (image, fallbackAltText = '') {
      return ((image?.imageAltText?.length > 0 ? image.imageAltText : fallbackAltText) + (image?.imageCredit?.length > 0 ? ` - Foto: ${image.imageCredit}` : '')).replace(/['"]+/g, '')
    }
  }
}
