import axios from 'axios'

const axiosInstance = axios.create()

const is4xxError = (error) => error.response?.status >= 400 && error.response?.status < 500
const ignore4xxList = ['/user/login', '/user/register', '/user/captcha', '/subscriptions/pur']
const isIgnoredPath = (url) => ignore4xxList.some(path => url.endsWith(path))

axiosInstance.interceptors.request.use(requestConfig => {
  requestConfig.headers = requestConfig.headers || {}
  return requestConfig
})

// eslint-disable-next-line no-unused-vars
let _store

export function setStore (store) {
  _store = store
}

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Skip handling for 4xx errors on register + login because already handled in the component
    if (is4xxError(error) && isIgnoredPath(error.request.responseURL)) {
      return Promise.reject(error)
    }
    const responseErrorMessage = error.response?.data?.message
    // Set details for error reporting to backend /log
    if (responseErrorMessage) {
      error.message += ' : ' + responseErrorMessage
    }

    let err = String(error)
    if (axios.isAxiosError(error)) {
      err = error.toJSON()
    }
    console.warn('Error from backend', error.request?.responseURL, err)

    return Promise.reject(error)
  }
)

/* User APIs */

export async function register (config, email, password, acceptedTermsAndConditions, acceptedDataPrivacyPolicy, brand, role, newsletter, captcha) {
  await axiosInstance.post('/user/register', {
    email,
    password,
    acceptedTermsAndConditions,
    acceptedDataPrivacyPolicy,
    brand,
    role,
    newsletter,
    captcha
  }, config)
}

export async function getCaptcha (config) {
  const { data } = await axiosInstance.get('/user/captcha', config)
  return data
}

export async function activate (config, oneTimePassword, newsletter, brand) {
  const { data } = await axiosInstance.post('/user/activate', {
    oneTimePassword,
    newsletter,
    brand
  }, config)
  return data
}

export async function login (config, email, password, brand) {
  const { data } = await axiosInstance.post('/user/login', {
    email,
    password,
    brand
  }, config)
  return data
}

export async function facebookLogin (config, accessToken, facebookId, brand) {
  const { data } = await axiosInstance.post('/user/login/facebook', {
    accessToken,
    facebookId,
    brand
  }, config)
  return data
}

export async function facebookRegister (config, accessToken, facebookId, brand) {
  const { data } = await axiosInstance.post('/user/register/facebook', {
    accessToken,
    facebookId,
    brand
  }, config)
  return data
}

export async function getOneTimePassword (config, userId, brand) {
  console.log(config)
  const { data } = await axiosInstance.post('/user/get-otp', {
    brand
  }, config)
  return data.oneTimePassword
}

export async function resetPassword (config, email, brand) {
  const { data } = await axiosInstance.post('/user/reset-password', { email, brand }, config)
  return data
}

export async function getUserProfile (config) {
  const { data } = await axiosInstance.get('/user/profile', config)
  return data
}

/**
 *
 * @param config
 * @param {string} amount
 * @param {string} paymentMethodId
 * @returns {Promise<any>}
 */
export async function createPaymentIntentForStoredCard (config, { amount, paymentMethodId }) {
  const { data } = await axiosInstance.post('/payments/create-client-payment-intent-for-stored-card', {
    amount,
    paymentMethodId
  }, config)
  return data
}

/**
 *
 * @param config
 * @param {string} amount
 * @param {boolean} shouldSetupCardForFutureUsage
 * @returns {Promise<any>}
 */
export async function createPaymentIntent (config, { amount, shouldSetupCardForFutureUsage }) {
  const { data } = await axiosInstance.post('/payments/create-client-payment-intent', {
    amount,
    shouldSetupCardForFutureUsage
  }, config)
  return data
}

/**
 *
 * @param config
 * @returns {Promise<any>}
 */
export async function createSetupIntent (config) {
  const { data } = await axiosInstance.post('/payments/create-client-setup-intent', {}, config)
  return data
}

/* Assets */
export async function getAssetByQuery (config, params) {
  const { data } = await axiosInstance.get(`/assets/${params.type.toLowerCase()}s`, {
    ...config,
    params
  })
  if (data.total !== 0) {
    return data.results[0]
  }
}

export async function checkDisplayNameExists (config, params) {
  const { data } = await axiosInstance.get('/assets/profiles/exists', {
    ...config,
    params
  })
  return data.exists
}

export async function postAsset (config, params) {
  const { data } = await axiosInstance.post(`/assets/${params.type.toLowerCase()}s?returnEntity=true`, params.asset, config)
  return data
}

export async function patchAsset (config, params) {
  await axiosInstance.patch(`/assets/${params.type.toLowerCase()}s/${params.id}`, params.asset, config)
}

export async function changePassword (config, oldPassword, newPassword) {
  await axiosInstance.post('/user/change-password', { oldPassword, newPassword }, config)
}

export async function changePasswordOtp (config, oneTimePassword, newPassword, brand) {
  await axiosInstance.post('/user/change-password-otp', { oneTimePassword, newPassword, brand }, config)
}

export async function deleteUser (config) {
  const { data } = await axiosInstance.delete('/user', config)
  return data
}

export async function newsletterSubscribe (config, brand, email, type) {
  await axiosInstance.post('/newsletter/subscribe/', {
    brand, email, type
  }, config)
}

export async function refreshAuthToken (config) {
  const { data } = await axiosInstance.post('/user/renew-token', {}, config)
  return data.token
}

export async function loadFeatureFlags (config) {
  const { data } = await axiosInstance.get('/flags', config)
  return data.flags
}

export async function getPaymentMethods (config) {
  const { data } = await axiosInstance.get('/pur-subscription/customers/payment-methods', config)
  return data
}

export async function deletePaymentMethod (config, paymentMethodId) {
  await axiosInstance.delete(`/pur-subscription/customers/payment-methods/${paymentMethodId}`, config)
}

/**
 * Subscriptions
 *
 * @param {*} token  JWT of the user, generated through Stripe
 * @returns
 */
export async function getSubscription (config) {
  return axiosInstance.get('/pur-subscription/subscriptions/pur', config)
}

export async function addSubscription (config) {
  return axiosInstance.post('/pur-subscription/subscriptions/pur', {}, config)
}

export async function confirmSubscription (config) {
  return axiosInstance.post('/pur-subscription/subscriptions/pur/confirm', {}, config)
}

export async function deleteSubscription (config) {
  return axiosInstance.delete('/pur-subscription/subscriptions/pur', config)
}

export async function getSubscriptionProduct (config) {
  return axiosInstance.get('/pur-subscription/products/pur-subscription-product', config)
}

export async function getBillingAddress (config) {
  return axiosInstance.get('/pur-subscription/customers/billing-address', config)
}

export async function addBillingAddress (config, payload) {
  return axiosInstance.put('/pur-subscription/customers/billing-address', payload, config)
}

export async function addCard (config, cardToken) {
  return axiosInstance.post('/pur-subscription/customers/payment-methods/card', cardToken, config)
}

export async function addDefaultPaymentMethod (config, paymentMethodId) {
  return axiosInstance.put('/pur-subscription/customers/payment-methods/default', { paymentMethodId }, config)
}
