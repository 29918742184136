<template>
  <div class="bx-content bx-content--structure-full bx-product-group bx-product-auto-comparison bx-typo--product-group"
       data-tc="product-auto-comparison">
    <span class="bx-badge bx-badge--affiliates"
          data-tc="product-group-badge" />
    <div ref="autocomparisonwrapper"
         class="bx-product-auto-comparison__wrapper">
      <div ref="prevnextwrapper"
           class="bx-product-auto-comparison__prev-next">
        <i v-if="canScrollRight"
           class="icon icon-arrow_pager-next"
           @click="scroll('right')">
          <svg aria-label="nach rechts scrollen">
            <use href="/images/icon_sprite.svg#arrow_pager-next" />
          </svg>
        </i>
        <i v-if="canScrollLeft"
           class="icon icon-arrow_pager-prev"
           @click="scroll('left')">
          <svg aria-label="nach links scrollen">
            <use href="/images/icon_sprite.svg#arrow_pager-prev" />
          </svg>
        </i>
      </div>
      <div v-if="products?.length > 0"
           ref="autocomparisonslider"
           class="bx-product-auto-comparison__slider">
        <div class="bx-product-auto-comparison__products">
          <Product v-for="(product, productIndex) in unifiedProducts"
                   :key="`product-${productIndex}`"
                   product-group-type="autocomparison"
                   :product="product"
                   :product-index="productIndex"
                   :show-ranking="showRanking"
                   :is-content-commerce="isContentCommerce"
                   :is-preview="isPreview"
                   :brand="brand" />
        </div>
        <template v-if="propertyExists('editorialStarRating')">
          <span class="bx-product-auto-comparison__table-title">Unsere Bewertung</span>
          <div class="bx-product-auto-comparison__editorial-star-ratings">
            <EditorialStarRating
              v-for="(product, productIndex) in unifiedProducts"
              :key="`${productIndex}-rating`"
              size="small"
              :rating="product.editorialStarRating" />
          </div>
        </template>
        <template v-if="propertyExists('prosAndCons')">
          <span class="bx-product-auto-comparison__table-title">Vor- und Nachteile</span>
          <div class="bx-product-auto-comparison__pros-and-cons">
            <ProsAndCons
              v-for="(product, productIndex) in unifiedProducts"
              :key="`${productIndex}-pros-cons`"
              :display-max-items="3"
              :pros-and-cons="product.prosAndCons" />
          </div>
        </template>
        <span class="bx-product-auto-comparison__table-title">Weitere Informationen zum Produkt</span>
        <div class="bx-product-auto-comparison__anchors">
          <Anchor
            v-for="(product, productIndex) in unifiedProducts"
            :key="`${productIndex}-anchor`"
            :anchor="product.link.url" />
        </div>
        <template v-if="propertyExists('otherPartners')">
          <span class="bx-product-auto-comparison__table-title">Weitere Anbieter</span>
          <div class="bx-product-auto-comparison__other-partners">
            <OtherPartners
              v-for="(product, productIndex) in unifiedProducts"
              :key="`${productIndex}-partners`"
              :other-partners="product.otherPartners" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import imgSrcSet from '../../mixins/img-src-set'
import productGroup from '../../mixins/product-group'
import Product from '../shared/product/Product.vue'
import EditorialStarRating from '../shared/product/EditorialStarRating.vue'
import ProsAndCons from '../shared/product/ProsAndCons.vue'
import Anchor from '../shared/product/Anchor.vue'
import OtherPartners from '../shared/product/OtherPartners.vue'

export default {
  components: {
    Product,
    EditorialStarRating,
    ProsAndCons,
    Anchor,
    OtherPartners
  },
  mixins: [
    productGroup,
    imgSrcSet
  ],
  props: {
    products: {
      type: Array,
      default: () => []
    },
    showRanking: {
      type: Boolean,
      default: () => false
    },
    // Used by mixin productGroup (we can't use store prop because of GUI preview)
    // eslint-disable-next-line vue/no-unused-properties
    brand: {
      type: String,
      default: null
    },
    isContentCommerce: {
      type: Boolean,
      default: () => {}
    },
    isPreview: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      canScrollRight: false,
      canScrollLeft: false,
      scrollAmount: 0,
      scrolling: false,
      scrollIntervals: [],
      itemWidth: 0,
      resizeObserver: null,
      // Used by mixin productGroup TODO: refactor this
      // eslint-disable-next-line vue/no-unused-properties
      prefix: 'bx'
    }
  },
  computed: {
    // This store prop is only used by a mixin
    ...mapState(useConfigStore, ['rsConfig']),
    // Used by mixin productGroup TODO: refactor this
    // eslint-disable-next-line vue/no-unused-properties
    productList () {
      return {
        type: 'autocomparison',
        products: this.products
      }
    }
  },
  watch: {
    products: {
      immediate: false,
      handler () {
        if (this.isPreview) {
          this.$nextTick(() => {
            if (this.products?.length > 0) {
              this.setWidths()
              this.checkIfCanScroll()

              if (!this.resizeObserver) {
                this.observePreviewWidth()
              }
            }
          })
        }
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('wheel', this.handleSwipe)
    window.addEventListener('scroll', this.handleScroll)
    if (this.products?.length > 0) {
      this.setWidths()
      this.checkIfCanScroll()
    }
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  methods: {
    vue3CompatibleBeforeDestroy () {
      this.clearAllIntervals()
      window.removeEventListener('resize', this.handleResize)
      window.removeEventListener('wheel', this.handleSwipe)
      window.removeEventListener('scroll', this.handleScroll)
      if (this.isPreview && this.resizeObserver) {
        this.resizeObserver.disconnect()
      }
    },
    calculateItemWidth (wrapperWidth) {
      const vpWidth = this.isPreview ? document.getElementById('asset-preview').clientWidth : window.innerWidth
      const factor = (vpWidth < 480) ? 2 : (vpWidth < 768) ? 3 : (vpWidth < 840) ? 2 : 3
      this.itemWidth = Math.floor(wrapperWidth / factor)
    },
    setItemsWidth (itemsList) {
      itemsList?.forEach((item) => {
        item.style.width = `${this.itemWidth}px`
      })
    },
    setWidths () {
      const wrapper = this.$refs.autocomparisonwrapper
      const wrapperWidth = wrapper.clientWidth

      const productsList = [...wrapper.querySelectorAll('a.bx-product')]
      const ratingsList = [...wrapper.querySelectorAll('div.bx-product__rating')]
      const prosAndConsList = [...wrapper.querySelectorAll('ul.bx-product__pros-and-cons')]
      const anchorsList = [...wrapper.querySelectorAll('a.bx-product__anchor')]
      const otherPartnersList = [...wrapper.querySelectorAll('div.bx-product__other-partners')]
      const allLists = [productsList, ratingsList, prosAndConsList, anchorsList, otherPartnersList]

      this.calculateItemWidth(wrapperWidth)
      allLists.forEach(this.setItemsWidth)
    },
    observePreviewWidth () {
      const previewContainer = document.getElementById('asset-preview')
      this.resizeObserver = new ResizeObserver(() => {
        this.setWidths()
        this.checkIfCanScroll()
      })
      this.resizeObserver.observe(previewContainer)
    },
    checkIfCanScroll () {
      const wrapper = this.$refs.autocomparisonslider

      if (!this.scrolling && wrapper !== undefined) {
        this.canScrollRight = wrapper.scrollLeft < wrapper.scrollWidth - wrapper.clientWidth
        this.canScrollLeft = wrapper.scrollLeft > 0
      }
    },
    scroll (direction) {
      const wrapper = this.$refs.autocomparisonslider
      this.scrolling = true

      let exactScrollLeft = wrapper.scrollLeft

      const slideTimer = setInterval(() => {
        let scrollStep = Math.floor(this.itemWidth / 10)

        if (this.scrollAmount + scrollStep > this.itemWidth) {
          scrollStep = this.itemWidth - this.scrollAmount
        }

        exactScrollLeft = direction === 'right' ? (exactScrollLeft + scrollStep) : (exactScrollLeft - scrollStep)
        this.scrollAmount += scrollStep
        wrapper.scrollLeft = Math.floor(exactScrollLeft)

        if (this.scrollAmount >= this.itemWidth) {
          clearInterval(slideTimer)
          this.scrolling = false
          this.scrollAmount = 0
          this.checkIfCanScroll()
        }
      }, 15)
      this.scrollIntervals.push(slideTimer)
    },
    clearAllIntervals () {
      this.scrollIntervals.forEach(intervalId => clearInterval(intervalId))
      this.scrollIntervals = []
    },
    handleResize () {
      const sliderWrapper = this.$refs.autocomparisonslider
      this.setWidths()
      sliderWrapper.scrollLeft = 0
      this.checkIfCanScroll()
      this.clearAllIntervals()
    },
    handleSwipe (event) {
      if (event.deltaX !== 0) {
        this.checkIfCanScroll()
      }
    },
    handleScroll () {
      const wrapper = this.$refs.autocomparisonwrapper
      const prevNext = this.$refs.prevnextwrapper
      const wrapperBottom = wrapper.getBoundingClientRect().bottom
      const prevNextBottom = prevNext.getBoundingClientRect().bottom

      if ((prevNextBottom + 100) > wrapperBottom) {
        prevNext.style.transition = 'opacity 0.05s ease-in-out'
        prevNext.style.opacity = '0'
      } else if ((prevNextBottom) <= wrapperBottom) {
        prevNext.style.transition = 'opacity 0.05s ease-in-out'
        prevNext.style.opacity = '1'
      }
    },
    // check if at least one product in the auto comparison has the given property
    propertyExists (propertyName) {
      return this.unifiedProducts.some(product =>
        propertyName in product && product[propertyName] !== null
      )
    }
  }
}
</script>
