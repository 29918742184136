import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { getConfig } from '../stores/utils/helpers'
import { useSessionStore } from '../stores/session'
import { useConfigStore } from '../stores/config'

const CORRELATION_ID_HEADER = 'X-Correlation-ID'

function serializeError (error: any) {
  if (error) {
    if (typeof error === 'string') {
      return error
    }

    let { message, stack, correlationId } = error
    if (stack) {
      // reduce webpack stack traces sent to server
      stack = stack.split('\n').splice(3)
    }
    return { message, stack, correlationId }
  }
  return null
}

function getCurrentUrl () {
  return window.location.toString()
}

const ignoredErrorMessages = ['Failed to execute \'querySelector\' on \'Document']

function shouldIgnoreError (error) {
  const shouldIgnore = !!ignoredErrorMessages.find(x => error?.includes(x))
  if (shouldIgnore) {
    console.log('Ignored error: ' + error)
  }
  return shouldIgnore
}

function shouldNotSendError () {
  // no frontend error logging for SSR
  const isSSR = typeof window === 'undefined'
  // frontend error logging only on prod
  const isNotProd = process.env.NODE_ENV !== 'production'

  return isNotProd || isSSR
}

export function installErrorLogging (store) {
  if (shouldNotSendError()) {
    return
  }

  window.lastErrors = []

  const orgErrorFn = console.error
  console.error = (...args) => {
    window.lastErrors.push({
      url: getCurrentUrl(),
      timestamp: new Date().toISOString(),
      // make sure error is an string
      errorJSON: JSON.stringify(serializeError(args[0]))
    })
    return orgErrorFn(...args)
  }

  window.onerror = function globalExceptionHandler (msg, url, line, col, error) {
    const { message, stack } = error
    logError(store, msg, { error: { message, stack } }, { url, line, col })
    return true
  }

  window.onunhandledrejection = function globalPromiseRejectionHandler (event) {
    logError(store, `Unhandled promise rejection reason: ${event.reason}`)
    return true
  }

  // send log on user agent used by well known spammer
  if (navigator?.userAgent?.includes('M2004J19C')) {
    let cookies = []
    try {
      cookies = document.cookie.split(';').map(x => x.split('='))
    } catch (e) {
      console.error(e)
    }

    logError(store, 'Looks_sus!', null, { cookies })
  }
}

export const ErrorPlugin = {
  install (Vue, options) {
    Vue.prototype.$logError = function (message, error, ...data) {
      sendError(options.store, { message, error: serializeError(error), data })
    }

    if (shouldNotSendError()) {
      return
    }

    Vue.config.errorHandler = function (error, vm, info) {
      const { message, stack } = error
      sendError(options.store, { message, error: { message, stack }, data: { info } })
    }
  }
}

export function logError (store, message, error, data) {
  sendError(store, { message, error: serializeError(error), data })
}

export function sendError (store, logObj) {
  if (shouldNotSendError()) {
    return
  }

  if (shouldIgnoreError(logObj?.message)) {
    return
  }

  const sessionStore = useSessionStore()
  const configStore = useConfigStore()
  const requestConfig = getConfig(configStore.rsConfig.orchestrationServiceUrl, sessionStore.token)
  const correlationId = (logObj?.error?.correlationId || logObj?.data?.correlationId) || uuidv4()

  const lastErrors = window.lastErrors.splice(0)
  logObj.client = {
    url: getCurrentUrl(),
    userAgent: navigator.userAgent,
    lastErrors
  }

  const userId = sessionStore.user?.userId
  if (userId) {
    logObj.userId = userId
  }

  const roles = sessionStore.user?.roles
  if (roles) {
    logObj.roles = roles
  }

  console.error(`Reporting error with CorrelationID [${correlationId}]: ${logObj.message}`, logObj)

  requestConfig.headers[CORRELATION_ID_HEADER] = correlationId

  axios.post(
    '/log/error',
    logObj,
    requestConfig
  ).catch(e => {
    // Keep the last errors if there was an error while reporting the errors
    window.lastErrors = [...lastErrors, ...window.lastErrors]
    console.error(`Error while sending error log: ${e}`)
  })
}
