import type { Brand } from '@xcel-next/util'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export function sendGTMEvent (event: any, resetEvent: boolean) {
  window.dataLayer = window.dataLayer || []
  if (resetEvent) {
    window.dataLayer.push(resetEvent)
  }
  window.dataLayer.push(event)
}

export function getGtmEcommerceItem (brand: Brand, amount: number, isPreselectedAmount: boolean, idSuffix: string, transactionId: string) {
  return {
    transaction_id: transactionId,
    item_id: `SKU_000${idSuffix}`,
    item_name: `Guthaben ${isPreselectedAmount ? (amount / 100) + '€' : 'Custom'}`,
    currency: 'EUR',
    item_brand: brand,
    price: (amount / 100).toFixed(2),
    quantity: 1
  }
}
