<template>
  <div>
    <div :class="['bx-form__item', cssFormItemClass, {'bx-has-error': passwordError}]">
      <label for="select_password"
             class="bx-form__label">
        {{ contextBasedWording[formContext].label.select_password }}
      </label>
      <PasswordField id="select_password"
                     :value="value || modelValue"
                     :placeholder="contextBasedWording[formContext].placeholder.select_password"
                     class="bx-form__validation-follows-input"
                     autocomplete="new-password"
                     @update:modelValue="$emit('update:modelValue', $event)"
                     @input="$emit('input', $event)"
                     @focusout="passwordError = null" />
      <span v-if="passwordError"
            class="bx-form__message bx-form__message--error">
        {{ passwordError }}
      </span>
    </div>
    <PasswordValidation :value="value || modelValue"
                        @valid="passwordAccurate = $event" />
    <div :class="['bx-form__item', cssFormItemClass, {'bx-has-error': passwordRepeatError}]">
      <label for="repeat_password"
             class="bx-form__label">
        {{ contextBasedWording[formContext].label.repeat_password }}
      </label>
      <PasswordField id="repeat_password"
                     :value="passwordRepeat"
                     :placeholder="contextBasedWording[formContext].placeholder.repeat_password"
                     autocomplete="new-password"
                     @input="passwordRepeat = $event"
                     @update:modelValue="passwordRepeat = $event"
                     @focusout="passwordRepeatError = null;" />
      <span v-if="passwordRepeatError"
            class="bx-form__message bx-form__message--error">
        {{ passwordRepeatError }}
      </span>
    </div>
  </div>
</template>

<script>
import PasswordField from './PasswordField.vue'
import PasswordValidation from './PasswordValidation.vue'

export default {
  components: { PasswordField, PasswordValidation },
  props: {
    value: { // vue2
      type: String,
      required: false,
      default: ''
    },
    modelValue: { // vue3
      type: String,
      required: false,
      default: ''
    },
    cssFormItemClass: {
      type: String,
      default: null
    },
    formContext: {
      type: String,
      validator: (value) => ['renew', 'register'].includes(value),
      default: 'renew'
    }
  },
  emits: ['update:modelValue', 'input'],
  data () {
    return {
      contextBasedWording: {
        register: {
          label: {
            select_password: 'Passwort wählen',
            repeat_password: 'Passwort wiederholen'
          },
          placeholder: {
            select_password: 'Dein gewünschtes Passwort',
            repeat_password: 'Passwort wiederholen'
          }
        },
        renew: {
          label: {
            select_password: 'Neues Passwort wählen',
            repeat_password: 'Neues Passwort wiederholen'
          },
          placeholder: {
            select_password: 'Dein neues Passwort',
            repeat_password: 'Neues Passwort wiederholen'
          }
        }
      },
      passwordAccurate: false,
      passwordError: '',
      passwordRepeat: '',
      passwordRepeatError: ''
    }
  },
  methods: {
    // used from outside
    // eslint-disable-next-line vue/no-unused-properties
    validate () {
      if (this.value.length === 0) {
        this.passwordError = 'Bitte trage dein gewünschtes Passwort ein.'
      } else if (!this.passwordAccurate) {
        this.passwordError = 'Dein Passwort erfüllt nicht alle notwendigen Sicherheitskriterien.'
      } else {
        this.passwordError = null
      }

      if (this.passwordRepeat.length === 0) {
        this.passwordRepeatError = 'Bitte wiederhole dein gewünschtes Passwort.'
      } else if (this.value !== this.passwordRepeat) {
        this.passwordRepeatError = 'Dein gewünschtes Passwort und die Wiederholung stimmen nicht überein.'
      } else {
        this.passwordRepeatError = null
      }

      return !this.passwordError && !this.passwordRepeatError
    }
  }
}
</script>
