<template>
  <div ref="rootDiv"
       :class="productGroupStyleClasses"
       data-tc="product-group">
    <figure v-if="labelImageUrl"
            class="bx-product-group__label"
            data-tc="product-group-label">
      <img :src="labelImageUrl"
           :alt="productGroup.image.imageAltText">
    </figure>
    <div v-else-if="productGroup.label"
         class="bx-product-group__label"
         data-tc="product-group-label">
      <span>
        {{ productGroup.label }}
      </span>
    </div>
    <span v-else
          class="bx-product-group__label--no" />
    <AffiliateInfo v-if="productGroup?.hideAffiliateBadge !== true"
                   :manual-product-included="hasManualProduct()"
                   :product-group-type="productGroup.productGroupType" />
    <!-- eslint-disable vue/no-unused-refs MIXIN -->
    <div :id="flickitySliderId"
         ref="flickityDiv"
         :class="innerGroupStyleClasses"
         :data-tc="`product-group-${productGroup.productGroupType}`">
      <Product v-for="(product, productIndex) in unifiedProducts"
               :id="setSliderCellId(product, productIndex)"
               :key="`product-${productIndex}`"
               :product-group-type="productGroup.productGroupType"
               :product="product"
               :product-index="productIndex"
               :is-deals-product="isDeals"
               :is-content-commerce="isContentCommerce"
               :is-preview="isPreview"
               :brand="brand" />
      <template v-for="(product, productIndex) in unifiedProducts">
        <OtherPartners v-if="product.otherPartners?.length > 0"
                       :key="`product-${productIndex}-partners`"
                       title="Weitere Anbieter:"
                       :other-partners="product.otherPartners" />
      </template>
      <template v-for="(product, productIndex) in unifiedProducts">
        <EditorialStarRating v-if="product.editorialStarRating"
                             :key="`product-${productIndex}-rating`"
                             title="Unsere Bewertung:"
                             size="medium"
                             :rating="product.editorialStarRating" />
      </template>
      <template v-for="(product, productIndex) in unifiedProducts">
        <ProsAndCons v-if="product.prosAndCons"
                     :key="`product-${productIndex}-pros-and-cons`"
                     title-pros="Vorteile"
                     title-cons="Nachteile"
                     :pros-and-cons="product.prosAndCons"
                     :product-group-type="productGroup.productGroupType" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
import flickitySlider from '../../mixins/flickity-slider'
import imgSrcSet from '../../mixins/img-src-set'
import productGroup from '../../mixins/product-group'
import Product from '../shared/product/Product.vue'
import OtherPartners from '../shared/product/OtherPartners.vue'
import EditorialStarRating from '../shared/product/EditorialStarRating.vue'
import ProsAndCons from '../shared/product/ProsAndCons.vue'
import AffiliateInfo from '../shared/AffiliateInfo.vue'

export default {
  name: 'ParagraphTypeProductGroup',
  components: {
    Product,
    OtherPartners,
    EditorialStarRating,
    ProsAndCons,
    AffiliateInfo
  },
  mixins: [
    productGroup,
    flickitySlider,
    imgSrcSet
  ],
  props: {
    productGroup: {
      type: Object,
      default: () => {}
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    // Used by mixin productGroup (we can't use store prop because of GUI preview)
    // eslint-disable-next-line vue/no-unused-properties
    brand: {
      type: String,
      default: null
    },
    isContentCommerce: {
      type: Boolean,
      default: () => {}
    }
  },
  data () {
    return {
      focus: false,
      lastFocus: false,
      updatingCells: false,
      intervalId: false,
      flickityOptions: {},
      cellOrder: [],
      setSelected: 0,
      preClones: [],
      postClones: [],
      // Used by mixin productGroup TODO: refactor this
      // eslint-disable-next-line vue/no-unused-properties
      prefix: 'bx',
      isSlider: this.productGroup.productGroupType === 'slider',
      isDeals: this.productGroup.productGroupType === 'deals'
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins??
    ...mapState(usePageStore, ['viewportMetaModified']),
    flickitySliderId () {
      return this.isSlider || this.isDeals ? `paragraph-type-product-group-flickity-${this.sliderNo}` : false
    },
    productList () {
      return {
        type: this.productGroup.productGroupType,
        products: this.productGroup.products
      }
    }
  },
  watch: {
    productList (newData, oldData) {
      if (this.isPreview && (newData.type === 'slider' || newData.type === 'deals') && (newData.products?.length !== oldData.products?.length || newData.type !== oldData.type)) {
        if (this.flickity) {
          this.flickity.destroy()
        }
        this.unifyProducts(newData.products)
        this.setCellOrder()
        this.$nextTick(() => {
          this.initFlickitySlider(this.flickityOptions)
        })
      } else {
        if (this.isPreview && this.flickity && (newData.type !== 'slider' && newData.type !== 'deals')) {
          this.flickity.destroy()
        }
        this.unifyProducts(newData.products)
      }
    }
  },
  async mounted () {
    if ((this.isSlider || this.isDeals) && this.unifiedProducts) {
      this.setSelected = Math.round((this.unifiedProducts.length * 3) / 2)
      this.flickityOptions = {
        initialIndex: this.setSelected,
        cellAlign: 'center',
        autoPlay: !this.isPreview,
        freeScroll: false,
        groupCells: false,
        resize: false,
        on: {
          change: (index) => {
            if (!this.updatingCells && !this.isPreview) {
              this.updatingCells = true
              this.focus = index
              this.intervalId = setInterval(() => { this.updateCellOrder() }, 500)
            }
          }
        }
      }
      await this.initFlickitySlider(this.flickityOptions)
      // create array of slider cell id's
      this.setCellOrder()
      // wait until loading completed and insert cells to slider
      if (!this.isPreview) {
        document.addEventListener('readystatechange', () => {
          if (document.readyState === 'complete') {
            this.cellOrder.forEach((cellId, index) => {
              const originCell = this.$refs.rootDiv.querySelector(`#${cellId}`)
              this.preClones[index] = originCell.cloneNode(true)
              this.preClones[index].setAttribute('id', this.preClones[index].getAttribute('id') + '-pre')
              this.postClones[index] = originCell.cloneNode(true)
              this.postClones[index].setAttribute('id', this.postClones[index].getAttribute('id') + '-post')
              this.flickity.insert(originCell)
            })
            this.cellOrder = [...this.cellOrder.map(val => `${val}-pre`), ...this.cellOrder, ...this.cellOrder.map(val => `${val}-post`)]
            this.flickity.prepend(this.preClones)
            this.flickity.append(this.postClones)
            this.preClones = this.postClones = []
            this.flickity.select(this.setSelected)
            this.lastFocus = this.cellOrder[this.setSelected]
            this.flickity.resize()
            this.displayDealsendCountdown()
          }
        })
      }
    }
  },
  methods: {
    setSliderCellId (product, index) {
      return this.isSlider || this.isDeals ? `bx-js-slider-cell-${this.sliderNo}-${index}` : this.productGroup.productGroupType === 'single' ? product.link.url : false
    },
    setCellOrder () {
      this.cellOrder = []
      for (let i = 0; i < this.unifiedProducts.length; i++) {
        this.cellOrder.push(`bx-js-slider-cell-${this.sliderNo}-${i}`)
      }
    },
    updateCellOrder () {
      const selectedCell = this.$refs.rootDiv.querySelector('#' + this.flickitySliderId + ' .flickity-viewport .is-selected')
      if (selectedCell) {
        const selectedCellId = selectedCell.getAttribute('id')
        const activeKey = Object.keys(this.cellOrder).find(key => this.cellOrder[key] === selectedCellId)
        const latestKey = Object.keys(this.cellOrder).find(key => this.cellOrder[key] === this.lastFocus)
        const keyDiff = activeKey - latestKey
        for (let i = 0; i < Math.abs(keyDiff); i++) {
          if (keyDiff < 0) { // slide left
            const lastCell = this.cellOrder.pop()
            this.cellOrder.unshift(lastCell)
            const lastNode = this.$refs.rootDiv.querySelector(`#${lastCell}`)
            this.flickity.remove(lastNode)
            this.flickity.prepend(lastNode)
          } else if (keyDiff > 0) { // slide right
            const firstCell = this.cellOrder.shift()
            this.cellOrder.push(firstCell)
            const firstNode = this.$refs.rootDiv.querySelector(`#${firstCell}`)
            this.flickity.remove(firstNode)
            this.flickity.append(firstNode)
          }
        }
        this.lastFocus = selectedCellId
      }
      clearInterval(this.intervalId)
      this.updatingCells = false
    },
    formatNumber (number) {
      return number < 10 ? `0${number}` : number
    },
    displayDealsendCountdown () {
      const dealsendList = this.$refs.flickityDiv.querySelectorAll('[data-dealendtime]')

      const dealsCountdown = setInterval(() => {
        const currentTime = new Date().getTime()

        dealsendList.forEach((deal) => {
          const endTime = new Date(deal.dataset.dealendtime).getTime()
          const timeLeft = endTime - currentTime - (1000 * 60 * 60)
          const hours = this.formatNumber(Math.floor(timeLeft / (1000 * 60 * 60)))
          const minutes = this.formatNumber(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)))
          const seconds = this.formatNumber(Math.floor((timeLeft % (1000 * 60)) / 1000))

          if (Math.floor((timeLeft / 1000) < 1)) {
            deal.innerHTML = 'Deal beendet'
            clearInterval(dealsCountdown)
          } else {
            deal.innerHTML = `Endet in ${hours}:${minutes}:${seconds}</span>`
          }
        })
      }, 1000)
    }
  }
}
</script>
