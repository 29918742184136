<script>
import * as vue from 'vue'

import scriptLoader from '../../mixins/script-loader'

const getScriptSrcFromOembedHTML = html => {
  const src = html.match(/script async src\s*=\s*["'](.+?)["']/)

  return src ? src[1].trim() : null
}

export default {
  mixins: [scriptLoader],
  props: {
    html: {
      type: String,
      required: true
    }
  },
  async mounted () {
    const src = getScriptSrcFromOembedHTML(this.html)

    if (!src) {
      return
    }

    try {
      await this.loadScript(src)
      this.$emit('scriptLoaded', { success: true, src })
    } catch (error) {
      this.$emit('scriptLoaded', { success: false, src, error })
    }
  },
  // https://v3-migration.vuejs.org/breaking-changes/render-function-api.html
  render (createElement) {
    const { html } = this
    if (vue.version.startsWith('2')) {
      return createElement('div', {
        domProps: {
          innerHTML: html
        }
      })
    } else {
      return vue.h('div', {
        innerHTML: html
      })
    }
  }
}
</script>
