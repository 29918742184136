<template>
  <section class="bx-content bx-recipe__ingredients">
    <RecipeInterline title="Zutaten"
                     variant="ingredients" />
    <ServingCalculator :serving="serving"
                       @servingChanged="onServingChanged($event)" />
    <div v-for="(ingredientGroup, index) in proportionalIngredientGroups"
         :key="'ingredientGroups-' + index"
         class="bx-recipe__ingredients">
      <div v-if="ingredientGroup.name"
           class="bx-ingredient-group__title bx-typo--center bx-typo--h4">
        {{ ingredientGroup.name }}
      </div>
      <p v-for="(ingredient, ingredientIndex) in ingredientGroup.ingredients"
         :key="'ingredients-' + ingredientIndex">
        <span>{{ ingredient.quantityPrefix }} {{ ingredient.quantity }}  {{ ingredient.unit }} {{ ingredient.unitPostfix }}</span>
        <span v-if="ingredient.ingredientName">{{ ingredient.ingredientPrefix }} {{ ingredient.ingredientName }} {{ ingredient.ingredientPostfix }}</span>
        <span v-else-if="ingredient.rawText">{{ ingredient.rawText }}</span>
      </p>
      <AdaSlotMarker v-bind="{ aboveParagraph: { index: 2, type: 'recipeIngredientGroup' } }" />
    </div>
    <AmazonFresh v-if="showAmazonFreshButton"
                 title="Zutaten bestellen" />
  </section>
</template>

<script>
import { mapState } from 'pinia'

import ingredientQuantity from '../../../mixins/ingredient-quantity'
import RecipeInterline from './RecipeInterline.vue'
import ServingCalculator from './ServingCalculator.vue'
import AmazonFresh from './AmazonFresh.vue'
import { useConfigStore } from '../../../stores/config.js'
import AdaSlotMarker from '../../ad-slots/AdaSlotMarker.vue'

export default {
  components: { RecipeInterline, ServingCalculator, AmazonFresh, AdaSlotMarker },
  mixins: [ingredientQuantity],
  props: {
    ingredientGroups: {
      type: Array,
      default: () => []
    },
    serving: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      servingRatio: 1
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    proportionalIngredientGroups () {
      return this.ingredientGroups
        .map(group => {
          return {
            ...group,
            ingredients: group.ingredients?.map(ingredient => {
              const parsedQuantity = this.parseQuantity(ingredient.quantity)

              let formattedQuantity
              if (Number.isNaN(parsedQuantity)) {
                formattedQuantity = ingredient.quantity // if not a number, we just display whatever we received
              } else {
                const calculatedQuantity = parsedQuantity * this.servingRatio // if the quantity is a number, we scale it with the serving ratio
                formattedQuantity = this.isGrammIngredient(ingredient)
                  ? String(Math.round(calculatedQuantity)) // if the unit is gram, we don't use fractions and stuff, we round the quantity
                  : this.formatQuantity(calculatedQuantity)
              }
              return { ...ingredient, quantity: formattedQuantity }
            })
          }
        })
    },
    showAmazonFreshButton () {
      return this.rsConfig?.showAmazonFreshButton
    }
  },
  methods: {
    onServingChanged (servingRatio) {
      this.servingRatio = servingRatio
    },
    isGrammIngredient (ingredient) {
      const trimmedUnit = ingredient.unit?.trim()
      return trimmedUnit && (trimmedUnit === 'g' || trimmedUnit === 'gramm' || trimmedUnit === 'gram')
    }
  }
}
</script>
