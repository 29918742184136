import { mapState } from 'pinia'

import { useSourcepointStore } from '../stores/sourcepoint'
import urlHelper from './url-helper'

export default {
  mixins: [urlHelper],
  data () {
    return {
      pagestripQuery: null, // will be set by AdaPagestrip.vue to render dynamic pagestrip page
      pagestripHeaderId: null, // will be set by AdaPagestripHeader.vue to render pagestrip header
      pagestripMainId: null,
      embedId: 'adalliance-produkt-modern',
      scriptLoaded: false,
      scriptInitialized: false,
      timeoutCounter: 0,
      target: '_blank',
      rel: 'nofollow'
    }
  },
  head () {
    const metas = { script: [] }
    if (this.loadPagestrip) {
      metas.script.push({
        'data-vmid': 'ada-pagestrip-loader',
        key: 'ada-pagestrip-loader',
        src: 'https://j2.pagestrip.com/public/dist/player/loader.min.js',
        async: true,
        callback: () => { this.scriptLoaded = true }
      })
    }
    return metas
  },
  computed: {
    ...mapState(useSourcepointStore, ['consentsExist']),
    loadPagestrip () {
      return this.consentsExist && (this.pagestripQuery || this.pagestripHeaderId || this.pagestripMainId)
    }
  },
  watch: {
    consentsExist (newValue) {
      if (this.loadPagestrip && newValue) {
        this.initPagestrip()
      }
    },
    scriptLoaded (newValue) {
      if (this.loadPagestrip && newValue) {
        this.initPagestrip()
      }
    }
  },
  methods: {
    initPagestrip () {
      if (!this.consentsExist || !this.scriptLoaded || this.scriptInitialized) {
        return
      }

      document.body.dataset.adaPagestrip = 'true' // prevent min-height on above-the-fold ad slots

      // working example queryString: ?ps=eosZ6x2u:JJUtv68Y:adalliance-produkt-modern
      if (this.pagestripQuery) {
        const [lookupIdHeader, lookupIdMain, forceEmbedId] = this.pagestripQuery.split(':') || {}
        if (forceEmbedId) {
          this.embedId = forceEmbedId
        }
        // ensure, that the script is up and running - otherwise we'll try again 'later'
        if (!this.isPagestripAvailable()) return false
        this.renderContainer('pagestrip-header', this.embedId, lookupIdHeader)
        this.renderContainer('pagestrip-main', this.embedId, lookupIdMain)
        this.scriptInitialized = true
      } else if (this.pagestripHeaderId) {
        if (!this.isPagestripAvailable()) return false
        this.renderContainer('pagestrip-header', this.embedId, this.pagestripHeaderId)
        this.scriptInitialized = true
      } else if (this.pagestripMainId) {
        if (!this.isPagestripAvailable()) return false
        this.renderContainer('pagestrip-main', this.embedId, this.pagestripMainId)
        this.scriptInitialized = true
      }
    },
    renderContainer (id, embedId, lookupId) {
      if (lookupId) {
        window.pagestrip.render(document.getElementById(id), {
          preset: 'widget',
          publisher: { embedId },
          router: { history: 'memory' },
          content: { lookupId }
        })
      }
    },
    isPagestripAvailable () {
      if (!window.pagestrip) {
        setTimeout(() => {
          this.timeoutCounter++
          if (this.timeoutCounter < 5) {
            this.initPagestrip()
          }
        }, 500)
        return false
      }
      return true
    }
  }
}
