<template>
  <Dialog v-if="isClient && showExitIntent"
          :title="salutationSpecificTitle"
          class="bx-dialog--exit-intent"
          @close="closeOverlay">
    <template #header>
      <div class="bx-dialog__title">
        <div class="bx-typo--h2">
          {{ salutationSpecificTitle }}
        </div>
      </div>
    </template>

    <ParagraphTypeTeaserGroup teaser-group-type="bx-teaser-container--one-third-width"
                              :teasers="relatedAssets"
                              :brand="brandFromStore" />
  </Dialog>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import { usePageStore } from '../../stores/page'
import clientOnly from '../../mixins/client-only'
import ParagraphTypeTeaserGroup from '../teaser/TeaserGroup.vue'
import Dialog from '../shared/Dialog.vue'
import { fetchRelatedAssets } from '../../api'
import urlHelper from '../../mixins/url-helper'

export default {
  components: {
    Dialog,
    ParagraphTypeTeaserGroup
  },
  mixins: [urlHelper, clientOnly],
  data () {
    return {
      resetTimerEvents: ['mousemove', 'mousedown', 'click', 'keydown', 'scroll'],
      relatedAssets: [],
      showExitIntent: false,
      idleTimer: null,
      videoContent: {
        observer: [],
        listener: [],
        playing: 0
      }
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['pageData', 'brandFromStore']),
    config () {
      return this.rsConfig.adSlotUsage.exitIntentOverlay.config
    },
    salutationSpecificTitle () {
      return `Das könnte ${this.rsConfig.brandSalutation === 'informal' ? 'Dich' : 'Sie'} auch interessieren`
    },
    cocoTeaserTitle () {
      return `Hier ${this.rsConfig.brandSalutation === 'informal' ? 'findest du' : 'finden Sie'} die besten Amazon-Angebote des Tages`
    },
    cocoTeaser () {
      const { cocoTeaserUrl: url, cocoTeaserImageId: imageId } = this.config
      return url && imageId?.length
        ? {
            roofline: 'Sparen, aber mit Köpfchen',
            url,
            target: '_blank',
            rel: 'sponsored noopener',
            imageId,
            titleLong: this.cocoTeaserTitle,
            titleShort: this.cocoTeaserTitle,
            affiliate: true
          }
        : undefined
    }
  },
  watch: {
    /*
    * Watcher for playing counter that resets or stops the timer depending on a video status
    * */
    'videoContent.playing' () {
      if (!this.videoContent.playing) {
        this.resetTimer()
      } else {
        this.stopTimer()
      }
    }
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  mounted () {
    if (window.location.hash === '#exit-intent') {
      this.openOverlay()
    } else {
      this.enableTimer()
      this.checkVideoContent()
    }
    // make component accessible from outside for e2e tests
    if (process.env.NODE_ENV !== 'production') {
      window.bxExitIntent = this
    }
  },
  methods: {
    vue3CompatibleBeforeDestroy () {
      this.disableTimer()
    },
    /**
    * For videos in the content, we have to observe the containers
    * because they may not yet have consent or have not yet been initialized.
    * To do this, we mark them explicitly so that we can react to them afterwards
    * */
    checkVideoContent () {
      const videoNodes = document.querySelectorAll('.bx-content--video:not(.bx-placeholder)')
      videoNodes.forEach((node, index) => {
        node.dataset.observeVideo = `${index}`
        this.videoContent.observer[this.videoContent.count] = new MutationObserver(this.videoObserverCallback)
        this.videoContent.observer[this.videoContent.count].observe(document.querySelector(`[data-observe-video="${index}"]`), { childList: true, subtree: true })
      })
    },
    /**
    * Observer callback, which determines the video for which changes were made in the dom
    * and checks whether a video tag was inserted (video initialization).
    * In this case, listeners are created for the corresponding video event and the observer is terminated
    * */
    videoObserverCallback (mutationList, observer) {
      const observedVideoIndex = mutationList.map((mutation) => mutation.target.closest('[data-observe-video]'))[0].dataset.observeVideo
      const video = document.querySelector(`[data-observe-video="${observedVideoIndex}"] video`)
      if (video) {
        this.listenVideoStates(observedVideoIndex)
        observer.disconnect()
      }
    },
    /**
    * Create play and pause event listener callback for specific video
    * */
    listenVideoStates (videoIndex) {
      this.videoContent.listener[videoIndex] = document.querySelector(`[data-observe-video="${videoIndex}"] video`)
      if (this.videoContent.listener[videoIndex]) {
        this.videoContent.listener[videoIndex].addEventListener('play', this.videoListenerCallback)
        this.videoContent.listener[videoIndex].addEventListener('pause', this.videoListenerCallback)
      }
    },
    /**
     * Remove all play and pause event listener callback for video
     * */
    removeVideoListener () {
      this.videoContent.listener.forEach((listener, index) => {
        this.videoContent.listener[index].removeEventListener('play', this.videoListenerCallback)
        this.videoContent.listener[index].removeEventListener('pause', this.videoListenerCallback)
      })
    },
    /**
     * Callback for video event listener that increments and decrements the playing counter
     */
    videoListenerCallback (event) {
      if (event.type === 'play') {
        this.videoContent.playing++
      } else if (event.type === 'pause') {
        this.videoContent.playing--
      }
    },
    enableTimer () {
      this.resetTimer()
      this.resetTimerEvents.forEach(el => {
        document.addEventListener(el, this.resetTimer, true)
      })
      if (this.config.openOnMouseOut) {
        document.addEventListener('mouseout', this.detectViewportLeave, true)
      }
    },
    disableTimer () {
      this.stopTimer()
      this.resetTimerEvents.forEach(el => {
        document.removeEventListener(el, this.resetTimer, true)
      })
      if (this.config.openOnMouseOut) {
        document.removeEventListener('mouseout', this.detectViewportLeave, true)
      }
    },
    startTimer () {
      this.idleTimer = setTimeout(this.openOverlay, this.config.idleSeconds * 1000)
    },
    stopTimer () {
      clearTimeout(this.idleTimer)
    },
    resetTimer () {
      if (!this.showExitIntent) {
        this.stopTimer()
        this.startTimer()
      }
    },
    detectViewportLeave (e) {
      if (!this.videoContent.playing && !e.toElement && !e.relatedTarget) {
        this.openOverlay()
      }
    },
    async openOverlay () {
      const cocoTeaser = this.cocoTeaser
      const teaserCount = cocoTeaser ? 2 : 3
      this.relatedAssets = await fetchRelatedAssets(
        this.brandFromStore,
        this.pageData,
        this.rsConfig.proxyServiceUrl,
        teaserCount
      ) || []
      if (this.relatedAssets?.length === teaserCount) {
        this.showExitIntent = true
        if (cocoTeaser) {
          this.relatedAssets.push(cocoTeaser)
        }
      }
      this.disableTimer()
      this.removeVideoListener()
    },
    closeOverlay () {
      this.showExitIntent = false
    }
  }
}
</script>
