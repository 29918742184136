import deviceDetection from './device-detection'
import urlHelper from './url-helper'
export default {
  mixins: [deviceDetection, urlHelper],
  props: ['brand', 'pageData', 'isAmp'],
  computed: {
    googleTagManagerId () {
      // rsConfig is from configStore and imported by component that uses this mixin
      return this.isAmp ? this.rsConfig.googleTagManagerIdAmp : this.rsConfig.googleTagManagerId
    }
  },
  methods: {
    generateCustomDimensionsFromPageData () {
      if (this.pageData && this.pageData.body) {
        const mainTags = this.pageData.body.mainStructurePath ? this.pageData.body.mainStructurePath.filter(tag => tag.url && (tag.url !== '/')).map(tag => tag.name) : []
        const tags = Array.from(new Set([...mainTags, ...(this.pageData.body.additionalStructures ? this.pageData.body.additionalStructures.map(tag => tag.name) : [])]))
        const videoIds = []
        const paragraphTypes = []
        const embedTypes = []
        if (this.pageData.body.paragraphs) {
          this.pageData.body.paragraphs.forEach(paragraph => {
            if (paragraph.paragraphType === 'paragraphCollection') {
              if (Array.isArray(paragraph.paragraphs)) { // quickfix!!! might need refactoring (paragraph.paragraphs should not be undefined)
                paragraph.paragraphs.forEach(colParagraph => {
                  if (colParagraph.paragraphType === 'embed') {
                    if (!embedTypes.includes(colParagraph.embed.embedType)) embedTypes.push(colParagraph.embed.embedType)
                    paragraphTypes.push(`${colParagraph.paragraphType}(${colParagraph.embed.embedType})`)
                  } else {
                    if (colParagraph.paragraphType === 'video') videoIds.push(colParagraph.video.jwId)
                    paragraphTypes.push(colParagraph.paragraphType)
                  }
                })
              }
            } else {
              if (paragraph.paragraphType === 'embed') {
                if (!embedTypes.includes(paragraph.embed.embedType)) embedTypes.push(paragraph.embed.embedType)
                paragraphTypes.push(`${paragraph.paragraphType}(${paragraph.embed.embedType})`)
              } else {
                if (paragraph.paragraphType === 'video') videoIds.push(paragraph.video.jwId)
                paragraphTypes.push(paragraph.paragraphType)
              }
            }
          })
        }
        const customDimensions = {
          brand: this.brand,
          categories: mainTags.join('|'),
          mainCategory: mainTags[0],
          subCategory: mainTags[1],
          mainTag: mainTags.slice(-1)[0],
          contentType: (this.pageData.pageMeta.isSeriesMaster || this.pageData.pageMeta.isSeriesMember) ? 'seriesArticle' : this.pageData.pageMeta.pageType,
          articleType: this.pageData.pageMeta.articleType,
          pageType: this.pageData.pageMeta.nodeId ? 'article' : 'structureterm',
          authorFrontend: this.pageData.body.author ? this.pageData.body.author.titleLong : '',
          authorBackend: this.pageData.metaData.creator,
          tags,
          title: this.pageData.body.headline,
          roofline: this.pageData.body.roofline,
          wordCount: this.pageData.metaData.wordCount,
          charCount: this.pageData.metaData.charCount,
          hasVideo: this.pageData.metaData.hasVideo,
          hasQuiz: paragraphTypes.indexOf('quiz') !== -1,
          hasGallery: this.pageData.metaData.hasGallery,
          isAmp: !!this.isAmp, // set to `false` if `undefined`
          isDuplicate: this.pageData.metaData.isDuplicate,
          amazonCoop: this.pageData.metaData.cooperations ? this.pageData.metaData.cooperations.indexOf('Amazon') !== -1 : false,
          dateCreated: this.pageData.metaData.dateCreated,
          dateChanged: this.pageData.metaData.dateChanged,
          displayDate: this.pageData.body.displayDate,
          id: this.pageData.pageMeta.id,
          nodeId: this.pageData.pageMeta.nodeId,
          campaignName: this.pageData.metaData.campaignName,
          evergreenContent: !!this.pageData.metaData.evergreenContent,
          affiliateContent: !!this.pageData.metaData.affiliateContent,
          shorttermContent: !!this.pageData.metaData.shorttermContent,
          advisoryContent: !!this.pageData.metaData.advisoryContent,
          isContentHubContent: !!this.pageData.metaData.isContentHubContent,
          aiGeneratedContent: !!this.pageData.metaData.aiGeneratedContent,
          isContentCommerce: !!this.pageData.metaData.isContentCommerce,
          newsContent: !!this.pageData.metaData.newsContent,
          cocoContent: !!this.pageData.metaData.cocoContent,
          paragraphTypes: paragraphTypes.join(' | '),
          embedTypes: embedTypes.join(' | '),
          videoIds: videoIds.join(' | ')
        }
        if (this.pageData.metaData.adKeywords && this.pageData.metaData.adKeywords.length > 0) {
          const adKeyWord = this.pageData.metaData.adKeywords[0]
          if (adKeyWord.indexOf('ind_') === 0) {
            customDimensions.indAdKeyWord = adKeyWord.substr(4)
          } else {
            customDimensions.avtAdKeyWord = adKeyWord.indexOf('avt_') === 0 ? adKeyWord.substr(4) : adKeyWord
          }
        }
        return customDimensions
      }
    }
  },
  beforeMount () {
    if (window && this.googleTagManagerId && !this.isAmp && process.env.NODE_ENV !== 'development') {
      window.dataLayer = window.dataLayer || []
      if (typeof window.fuckAdBlock !== 'undefined') {
        window.fuckAdBlock.onDetected(() => {
          window.dataLayer.push({
            event: 'fuckadblock',
            adBlockerDetected: true
          })
        })
        window.fuckAdBlock.onNotDetected(() => {
          window.dataLayer.push({
            event: 'fuckadblock',
            adBlockerDetected: false
          })
        })
      }
      const queryParamReferrer = window.location.search.match(/referrer=(.+)/)
      queryParamReferrer && queryParamReferrer[1] && window.dataLayer.push({
        documentReferrer: decodeURIComponent(queryParamReferrer[1])
      })
      const data = this.generateCustomDimensionsFromPageData()
      data.tags = data.tags.join('|')
      window.dataLayer.push(data)
    }
  }
}
