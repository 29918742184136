<template>
  <div>
    <EmbedContentLoader :html="html"
                        @scriptLoaded="triggerEmbedScript" />
    <EmbedRevoke embed-name="Instagram"
                 :vendor-id="vendorId" />
  </div>
</template>

<script>
import EmbedContentLoader from '../../consent/EmbedContentLoader.vue'
import EmbedRevoke from '../../consent/EmbedRevoke.vue'

export default {
  components: {
    EmbedContentLoader,
    EmbedRevoke
  },
  props: {
    html: {
      type: String,
      default: ''
    },
    vendorId: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.triggerEmbedScript()
  },
  updated () {
    // on update, we need to trigger the embed converter again
    this.triggerEmbedScript()
  },
  methods: {
    triggerEmbedScript () {
      if (!import.meta.env.SSR && window && window.instgrm !== undefined) {
        window.instgrm.Embeds.process()
      }
    }
  }
}
</script>
