<template>
  <div class="bx-content bx-register"
       :class="view === 'success' ? 'bx-content-box bx-typo--content-box bx-typo--content-box-leak-left bx-content-box--discrete' : ''">
    <div v-if="view === 'register'">
      <form class="bx-form bx-form--pur bx-register__wrapper bx-typo--form"
            autocomplete="on"
            @submit.prevent="submit">
        <div :class="{'bx-has-error': errorMsg.email}">
          <label for="register_email"
                 class="bx-form__label">
            E-Mail-Adresse
          </label>
          <input id="register_email"
                 v-model="user.email"
                 name="register_email"
                 autocomplete="email"
                 type="text"
                 placeholder="Deine E-Mail-Adresse"
                 @focusout="clearValidationError('email')">
          <span v-if="errorMsg.email"
                class="bx-form__message bx-form__message--error">
            {{ errorMsg.email }}
          </span>
        </div>
        <PasswordRepeat ref="passwordRepeat"
                        v-model="user.password"
                        form-context="register" />
        <div class="bx-register__terms-and-conditions bx-form__message">
          <span>
            Mit deiner Registrierung erklärst du dich mit unseren
            <a href="/agb-pur"
               target="_blank"
               class="bx-typo--link">
              AGB
            </a>
            einverstanden. Bitte lies auch unsere
            <a href="/datenschutz-pur"
               target="_blank"
               class="bx-typo--link">
              Datenschutzerklärung.
            </a>
          </span>
        </div>

        <div v-if="captcha"
             :class="['bx-form__captcha', {'bx-has-error': errorMsg.captcha}]">
          <label for="captcha"
                 class="bx-form__label">
            Sicherheitsabfrage
          </label>
          <div class="bx-form__captcha-wrapper">
            <div class="bx-form__captcha-image"
                 v-html="captcha.svg" />
            <input id="captcha"
                   v-model="captcha.input"
                   name="captcha"
                   type="text"
                   placeholder="Bitte Ergebnis eintragen"
                   @focusout="clearValidationError('captcha')">
          </div>
          <span v-if="errorMsg.captcha"
                class="bx-form__message bx-form__message--error">
            {{ errorMsg.captcha }}
          </span>
        </div>

        <div v-if="showGlobalError"
             class="bx-form__message bx-form__message--error">
          Bei der Registrierung ist ein Fehler aufgetreten. Eventuell existiert bereits ein Account mit dieser E-Mail-Adresse.
          Bitte zunächst versuchen, das
          <a class="bx-typo--link-error"
             href="/passwort-vergessen">Passwort zurückzusetzen</a>.
          Sollte das Problem weiter bestehen, kann gerne unser
          <a class="bx-typo--link-error"
             href="/support">Support</a> kontaktiert werden.
        </div>
        <div class="bx-form__item bx-form__item--centered">
          <button type="submit"
                  class="bx-form__button"
                  :disabled="processing || preventSubmit"
                  :data-loader="processing">
            Jetzt registrieren
          </button>
        </div>

        <FacebookLogin v-if="isClient && facebookEnabled"
                       label="Mit Facebook registrieren"
                       @facebookLogin="onFacebookLogin" />
      </form>
    </div>
    <div v-if="view === 'success'">
      <h2 class="bx-typo--content-interline-h2">
        Fast geschafft!
      </h2>
      <div class="bx-typo--content-continuous-text">
        <h3 class="bx-typo--content-interline-h3">
          Nur noch ein Schritt: Bitte bestätige deine E-Mail-Adresse.
        </h3>
        <p>Schau in dein E-Mail-Postfach und klicke in der Bestätigungs-E-Mail auf den Link. Danach ist die Registrierung abgeschlossen.</p>
        <p>Falls du innerhalb der nächsten fünf Minuten keine E-Mail bekommst, sieh bitte in deinem Spam-Ordner nach.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'

import { useNotifyStore } from '../../../stores/notify'
import { useSessionStore } from '../../../stores/session'
import { useConfigStore } from '../../../stores/config'
import clientOnly from '../../../mixins/client-only'
import redirect from '../../../mixins/redirect'
import { getConfig } from '../../../stores/utils/helpers'
import { getCaptcha } from '../../../stores/utils/orc-api'
import { sendGTMEvent } from '../../../utils/gtm'
import { isEmail } from '../../../utils/strings.ts'
import FacebookLogin from '../../shared/FacebookLogin.vue'
import PasswordRepeat from '../../shared/PasswordRepeat.vue'

export default {
  components: { PasswordRepeat, FacebookLogin },
  mixins: [redirect, clientOnly],
  data () {
    return {
      processing: null,
      preventSubmit: true,
      showGlobalError: false,
      validationError: false,
      captcha: null,
      errorMsg: {
        email: null,
        termsOfUseConfirmation: null,
        privacyPolicyConfirmation: null,
        password: null,
        captcha: null
      },
      view: 'success',
      user: {
        email: '',
        password: '',
        termsOfUseConfirmation: null,
        privacyPolicyConfirmation: null
      }
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSessionStore, {
      isLoggedIn: 'isLoggedIn',
      hasPurSub: 'hasPurSub',
      userFromStore: 'user',
      token: 'token'
    }),
    facebookEnabled () {
      return this.rsConfig.socialSettings?.socialLoginEnabled?.facebook
    }
  },
  beforeMount () {
    if (this.isLoggedIn) {
      if (this.hasPurSub) {
        window.location.href = '/'
      } else {
        window.location.href = '/mein-abo'
      }
    }
  },
  created () {
    this.clearForm()
  },
  async mounted () {
    // activate the form after the page is loaded
    this.preventSubmit = false
    this.captcha = await getCaptcha(getConfig(this.rsConfig.orchestrationServiceUrl, this.token))
  },
  methods: {
    ...mapActions(useSessionStore, ['facebookRegister', 'registerUser']),
    ...mapActions(useNotifyStore, ['showNotification']),
    clearForm (newView = 'register') {
      this.validationError = false
      this.view = newView
      this.user.email = ''
      this.user.password = ''
      this.user.termsOfUseConfirmation = true
      this.user.privacyPolicyConfirmation = true
    },
    clearValidationError (property) {
      this.errorMsg[property] = null
    },
    validateFormData () {
      if (this.user.email.length === 0) {
        this.errorMsg.email = 'Bitte trage deine E-Mail-Adresse ein.'
      } else if (!isEmail(this.user.email)) {
        this.errorMsg.email = 'Bitte trage eine gültige E-Mail-Adresse ein.'
      } else {
        this.errorMsg.email = null
      }

      this.errorMsg.password = !this.$refs.passwordRepeat.validate()

      if (!this.user.termsOfUseConfirmation) {
        this.errorMsg.termsOfUseConfirmation = 'Bitte bestätige, dass du unsere AGB gelesen hast und sie akzeptierst.'
      } else {
        this.errorMsg.termsOfUseConfirmation = null
      }

      if (!this.user.privacyPolicyConfirmation) {
        this.errorMsg.privacyPolicyConfirmation = 'Bitte bestätige, dass du unsere Datenschutzbestimmungen gelesen und zur Kenntnis genommen hast.'
      } else {
        this.errorMsg.privacyPolicyConfirmation = null
      }

      if (!this.captcha.input) {
        this.errorMsg.captcha = 'Bitte Ergebnis eintragen.'
      } else {
        this.errorMsg.captcha = null
      }

      this.validationError = Object.values(this.errorMsg).some(message => message)
    },
    async submit () {
      this.showGlobalError = false
      this.processing = true
      try {
        this.validateFormData()
        if (this.validationError) {
          this.processing = null
          this.showNotification({
            type: 'error',
            title: 'Fehler beim Speichern',
            message: 'Bitte überprüfe deine Eingaben.'
          })
          return
        }
        const validatedFormData = {
          email: this.user.email,
          password: this.user.password,
          acceptedTermsAndConditions: this.user.termsOfUseConfirmation,
          acceptedDataPrivacyPolicy: this.user.privacyPolicyConfirmation,
          newsletter: this.user.newsletter,
          captcha: this.captcha
        }

        try {
          await this.registerUser(validatedFormData)
        } catch (e) {
          const responseErrorMessage = e.response?.data?.message
          if (responseErrorMessage === 'Wrong captcha') {
            this.errorMsg.captcha = 'Bitte trage das Ergebnis der angezeigten Rechenaufgabe ein.'
            // Refresh captcha on error
            this.captcha = null
            this.captcha = await getCaptcha(getConfig(this.rsConfig.orchestrationServiceUrl, this.token))
          }
          throw e
        }

        this.clearForm('success')
      } catch (err) {
        console.error('Error on register.submit', err)
        const error = err?.response?.data ?? err
        if (error.status === 400 && error.message === 'invalid email') {
          this.errorMsg.email = 'Bitte trage eine gültige E-Mail-Adresse ein.'
        } else {
          this.showGlobalError = true
        }
        this.processing = null
      }
    },
    async onFacebookLogin (authResponse) {
      if (!authResponse) { return }
      this.showGlobalError = false
      this.processing = true
      const { accessToken, userID: facebookId } = authResponse
      try {
        if (!accessToken || !facebookId) {
          throw new Error('Facebook access token or id missing')
        }
        await this.facebookRegister({ accessToken, facebookId })
        const gtmEvent = { event: 'login', userId: this.userFromStore?.userId }
        sendGTMEvent(gtmEvent, { userId: undefined })
        this.redirectToSubscriptionPageOrReferrer('login-success')
      } catch (error) {
        console.error('Error on Facebook registration', error)
        this.showGlobalError = true
      } finally {
        this.processing = null
      }
    }
  }
}
</script>
