<template>
  <!-- eslint-disable vue/no-unused-refs MIXIN -->
  <div ref="flickityDiv"
       class="bx-content bx-slider bx-slider--mini-preview-navi">
    <div v-for="(item, index) in previewItems"
         :id="`bx-js-slider-cell-${sliderNo}-${index}`"
         :key="`slider-cell-${sliderNo}-${index}`"
         class="bx-slider__cell"
         @click="$emit('show:cell', index)">
      <figure v-if="item.type === 'image'">
        <img :src="getImgSrc(item.image)"
             loading="lazy"
             width="16"
             height="9">
      </figure>
      <div v-else
           :data-embed-type="item.icon"
           :title="getIconTitle(item.icon)"
           class="bx-slider__preview-icon-wrapper">
        <Icon :name="item.icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../../stores/config'
import imgSrcSet from '../../../mixins/img-src-set'
import flickitySlider from '../../../mixins/flickity-slider'
import Icon from '../../shared/Icon.vue'

export default {
  components: { Icon },
  mixins: [imgSrcSet, flickitySlider],
  props: {
    galleryItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    brand: {
      type: String,
      default: null
    },
    sliderNo: {
      type: Number,
      default: 0
    },
    selectedIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    previewItems () {
      return this.galleryItems.map((item) => {
        switch (item.paragraphType) {
          case 'teaser': return { type: 'image', image: item.teaser }
          case 'image': return { type: 'image', image: item.image }
          case 'embed': return { type: 'icon', icon: item.embed.embedType }
          case 'video': return { type: 'icon', icon: 'play' }
          default: return {}
        }
      })
    }
  },
  watch: {
    selectedIndex (newVal, oldVal) {
      this.selectActiveMainSliderImage(newVal, oldVal)
    }
  },
  async mounted () {
    await this.initFlickitySlider({
      initialIndex: this.selectedIndex,
      wrapAround: true,
      groupCells: false,
      prevNextButtons: false,
      adaptiveHeight: true,
      pageDots: false,
      cellAlign: 'center'
    })
    this.selectActiveMainSliderImage(this.selectedIndex)
  },
  methods: {
    getIconTitle (icon) {
      return icon === 'play'
        ? this.rsConfig.actualBrandName + ' Video'
        : icon.charAt(0).toUpperCase() + icon.slice(1) + ' Embed'
    },
    getImgSrc (image) {
      return this.createImageUrl(image, '220', image?.croppings?.landscape, this.brand, 'webp')
    },
    selectActiveMainSliderImage (newVal, oldVal = null) {
      this.flickity.selectCell(newVal, false, false)
      document.getElementById(`bx-js-slider-cell-${this.sliderNo}-${newVal}`)?.classList?.add('bx-slider__cell--is-selected-main')
      document.getElementById(`bx-js-slider-cell-${this.sliderNo}-${oldVal}`)?.classList?.remove('bx-slider__cell--is-selected-main')
    }
  }
}
</script>
