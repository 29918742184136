// import clone from 'clone'
import { createPinia } from 'pinia'

import {
  setStore
} from './utils/orc-api'

const createStore = () => {
  const store = createPinia()
  // TODO: do we need to clone here?? afawk NO
  setStore(store)
  return store
}

export {
  createStore
}
