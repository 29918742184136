<template>
  <div>
    <EmbedContentLoader :html="html"
                        @scriptLoaded="triggerEmbedScript" />
    <EmbedRevoke embed-name="Facebook"
                 :vendor-id="vendorId" />
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../../stores/config'
import EmbedContentLoader from '../../consent/EmbedContentLoader.vue'
import EmbedRevoke from '../../consent/EmbedRevoke.vue'

export default {
  components: {
    EmbedContentLoader,
    EmbedRevoke
  },
  props: {
    html: {
      type: String,
      default: ''
    },
    vendorId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig'])
  },
  created () {
    this.triggerEmbedScript()
  },
  mounted () {
    this.triggerEmbedScript()
  },
  updated () {
    // on update, we need to trigger the embed converter again
    this.triggerEmbedScript()
  },
  methods: {
    triggerEmbedScript () {
      if (!import.meta.env.SSR) {
        if (window.FB !== undefined) {
          // NOTE: this is not documented but i found this method in the FB script
          window.FB.XFBML.parse()
        } else {
          const fbConfig = this.rsConfig.embeds.facebook
          window.fbAsyncInit = function () {
            FB.init({
              xfbml: true, // this option will look out for embeds and replace them for us
              autoLogAppEvents: fbConfig.autoLogAppEvents,
              version: fbConfig.version
            })
          };
          (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) { return }
            const js = d.createElement(s); js.id = id
            js.src = fbConfig.scriptUrl
            fjs.parentNode.insertBefore(js, fjs)
          }(document, 'script', 'facebook-jssdk'))
        }
      }
    }
  }
}
</script>
