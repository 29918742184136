export const greaterThanNow = (dateValue) => {
  return new Date(dateValue) > new Date()
}

export function setCookie (name, value, expDate) {
  document.cookie = `${name}=${value};expires=${expDate.toUTCString()};path=/`
}

export function getCookie (name) {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
}

export const getConfig = (orchestrationServiceUrl, token) => {
  const cfg = {
    baseURL: orchestrationServiceUrl,
    headers: {}
  }

  if (token) {
    cfg.headers.Authorization = token
  }

  return cfg
}

export async function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Retry given async function until it doesn't throw an exception and
 * check() returns true.
 *
 * The delay doubles every try, e.g. 0ms, 100ms, 200ms, 400ms, 800ms.
 */
export async function retryFn (fn, check, retries, delay, maxDelay = Number.MAX_SAFE_INTEGER) {
  for (let retry = 0; retry < retries; retry++) {
    if (retry > 0) {
      const sleepMs = 2 ^ retry * delay
      const ms = sleepMs > maxDelay ? maxDelay : sleepMs
      await sleep(ms)
    }

    try {
      const result = await fn()
      if (check(result)) {
        return result
      }
    } catch (error) {
      // throw error if on last retry
      if (retry + 1 >= retries) {
        throw error
      }
    }
  }
}
