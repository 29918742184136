<template>
  <div class="bx-subscription__payment-method-form">
    <h3 class="bx-typo--content-interline-h3">
      Neue Kreditkarte hinterlegen
    </h3>
    <!-- Stripe components access window which is not available in SSR -->
    <StripeElementCard v-if="isClient"
                       ref="elementRef"
                       :pk="publishableKey"
                       :test-mode="testMode"
                       :locale="locale"
                       @token="tokenCreated" />

    <div class="bx-form__item bx-form__item--centered">
      <button class="bx-form__button bx-form__button--secondary"
              :disabled="processing"
              @click="cancel">
        Abbrechen
      </button>
      <button class="bx-form__button"
              :disabled="processing"
              :data-loader="processing"
              @click="submit">
        Karte hinzufügen
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { usePaymentStore } from '../../stores/payment'
import { useNotifyStore } from '../../stores/notify'
import { useConfigStore } from '../../stores/config'
import clientOnly from '../../mixins/client-only'

export default {
  mixins: [clientOnly],
  data () {
    return {
      processing: null,
      publishableKey: '',
      testMode: process.env.NODE_ENV !== 'production',
      locale: 'de'
    }
  },
  computed: {
    ...mapState(usePaymentStore, ['paymentMethods']),
    ...mapState(useConfigStore, ['rsConfig'])
  },
  mounted () {
    try {
      this.publishableKey = this.rsConfig.stripePublicKey
    } catch (error) {
      console.error('Error on PaymentMethodForm', error)
    }
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    ...mapActions(usePaymentStore, ['addCard']),
    submit () {
      // This triggers Stripes "give me a token" function
      this.processing = true
      this.$refs.elementRef.submit()
    },
    cancel () {
      this.$emit('cancelPaymentMethod')
    },
    async tokenCreated (token) {
      try {
        // After submit, we get the token -> save to user, show new card
        await this.addCard(token.id)
        // Card will be added to top of paymentMethods
        this.$emit('paymentMethodChosen', this.paymentMethods[0])
        this.$emit('refreshPaymentMethods')
      } catch (error) {
        this.showNotification({
          type: 'error',
          title: 'Fehler beim Hinzufügen der Karte',
          message: error?.response?.data?.message ?? 'Die Karte konnte nicht hinzugefügt werden, bitte überprüfe die eingegebenen Daten.'
        })
      } finally {
        this.processing = null
      }
    }
  }
}
</script>
