<template>
  <div v-if="isClient && enabled"
       :ref="`marker-${aboveParagraph.index}`"
       class="bx-ada-marker">
    <AdaAdSlot v-if="renderSlot"
               :slot-name="adaSlotData.slotName"
               :slot-id="adaSlotData.slotId" />
    <AdaNativeCrossbar v-if="renderNativeCrossbarTop"
                       position="top" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useAdaStore } from '../../stores/ada'
import clientOnly from '../../mixins/client-only'
import AdaAdSlot from './AdaAdSlot.vue'
import AdaNativeCrossbar from './AdaNativeCrossbar.vue'

// define paragraphs where we don't want to show ad slots afterwards
const EXCLUDE_PARAGRAPHS = ['interline', 'horizontalLine', 'adaNativeCrossbar', 'buttonGroup', 'lexicon']

export default {
  components: { AdaAdSlot, AdaNativeCrossbar },
  mixins: [clientOnly],
  props: {
    aboveParagraph: {
      type: Object,
      default: () => {
        return {
          index: -1,
          type: null,
          wordcount: -1,
          nested: false
        }
      }
    }
  },
  data () {
    return {
      offsetTop: -1,
      enabled: true,
      renderSlot: false,
      renderNativeCrossbarTop: false,
      parentOffsetTop: null,
      parentInnerWidth: null,
      parentInnerHeight: null
    }
  },
  computed: {
    ...mapState(useAdaStore, ['blockAds', 'getActivatedAdSlotMarker']),
    adaSlotData () {
      let [slotName, slotId] = this.renderSlot.split('_')
      if (slotName === 'outstream') {
        slotName = 'outstream_div'
        slotId = 0
      }
      return {
        slotName,
        slotId: parseInt(slotId)
      }
    }
  },
  watch: {
    /**
     * Watch for activation request of a specific ad slot or disable the ad slot marker
     */
    getActivatedAdSlotMarker (newVal) {
      if (newVal[this.offsetTop]?.renderSlot) {
        this.activateRequestedSlot(newVal[this.offsetTop])
      } else {
        this.removeAdSlotMarker()
      }
    }
  },
  beforeMount () {
    // directly remove ad slot marker if we want to prevent ad slots after specific paragraph types
    if (EXCLUDE_PARAGRAPHS.includes(this.aboveParagraph.type)) {
      this.removeAdSlotMarker()
    } else {
      // lets wait for the next tick to get the correct offsetTop
      this.$nextTick(() => {
        // fetch marker position and width
        const marker = this.$refs[`marker-${this.aboveParagraph.index}`]
        if (marker) {
          this.offsetTop = Math.floor(marker.getBoundingClientRect().top + window.scrollY)
          this.parentOffsetTop = Math.floor(marker.parentNode.getBoundingClientRect().top + window.scrollY)
          this.parentInnerWidth = marker.parentNode.getBoundingClientRect().width
          this.parentInnerHeight = marker.parentNode.getBoundingClientRect().height
          // push marker data to store and watch for activation request of a specific ad slot
          this.addAdSlotMarker({
            index: `${this.aboveParagraph.index}`,
            type: `${this.aboveParagraph.type}`,
            offsetTop: this.offsetTop,
            parentOffsetTop: this.parentOffsetTop,
            parentInnerWidth: this.parentInnerWidth,
            parentInnerHeight: this.parentInnerHeight
          })
        } else {
          this.removeAdSlotMarker()
        }
      })
    }
  },
  methods: {
    ...mapActions(useAdaStore, ['addAdSlotMarker']),

    /**
     * called by watcher if we have an activation request for a specific ad slot
     */
    activateRequestedSlot (activationData) {
      const MIN_HEIGHT_FOR_RECTANGLE_ASIDE_PLACEMENT = 125
      if (activationData.renderSlot === 'ncb_top') {
        this.renderNativeCrossbarTop = true
      } else {
        this.renderSlot = activationData.renderSlot
        if (
          (
            this.renderSlot.includes('rectangle') &&
            ['mediumDesktop', 'largeDesktop'].includes(activationData.screenType) &&
            !this.aboveParagraph?.nested &&
            this.parentInnerHeight >= MIN_HEIGHT_FOR_RECTANGLE_ASIDE_PLACEMENT
          ) ||
          this.renderSlot.includes('ncb_top')
        ) {
          this.$refs[`marker-${this.aboveParagraph.index}`].parentNode.classList.add('bx-collection', 'bx-ada--slot-wrapper')
        }
      }
    },

    /**
     * disable and remove ad slot marker
     */
    removeAdSlotMarker () {
      this.enabled = false
      this.$emit('destroy')
    }
  }
}
</script>
