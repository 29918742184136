<template>
  <figure>
    <a v-if="image.externalUrl && image.externalUrl.length > 0"
       :href="image.externalUrl"
       :rel="imageRel"
       :target="imageTarget">
      <slot name="sourceSet" />
    </a>
    <slot v-else
          name="sourceSet" />
    <figcaption class="bx-typo--content-continuous-text">
      <span v-if="image.imageDescription && image.imageDescription.html && image.imageDescription.html.length > 0"
            class="bx-content__media-caption bx-content__media-img-description"
            data-tc="image-description"
            v-html="postFormatImageDescription(image.imageDescription.html)" />
      <span v-if="image.imageCredit && image.imageCredit.length > 0"
            class="bx-content__media-credit bx-content__media-img-credit"
            data-tc="image-credit">
        Foto: {{ image.imageCredit }}
      </span>
    </figcaption>
    <PinterestShareButton v-if="showPinterestShareBadge"
                          type="badge" />
  </figure>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../../stores/config'
import { usePageStore } from '../../../stores/page'
import PinterestShareButton from '../PinterestShareButton.vue'

export default {
  components: { PinterestShareButton },
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    listOrderPosition: {
      type: Number,
      default: -1
    },
    target: {
      type: String,
      default: null
    },
    rel: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['pageData']),
    showPinterestShareBadge () {
      return !this.isPreview &&
        this.rsConfig.socialSettings.socialShare.article.includes('pinterest') &&
        this.listOrderPosition === 0 &&
        this.pageData?.pageMeta?.pageType === 'article' &&
        (!this.pageData?.metaData?.advertorial || (this.pageData?.metaData?.advertorial && this.pageData?.metaData?.hideAdvertorial))
    },
    imageRel () {
      return this.image.rel ? this.image.rel : this.rel
    },
    imageTarget () {
      return this.image.target ? this.image.target : this.target
    }
  },
  methods: {
    postFormatImageDescription (html) {
      return html?.length ? html.replace(/<\/?(p|h2|h3|br)(.|\n)*?>/gi, ' ') : ''
    }
  }
}
</script>
