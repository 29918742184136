<template>
  <div class="bx-form__pass-criteria">
    <span v-for="criteria in passwordCriteria"
          :key="criteria.name"
          :class="{'bx-js-checked': criteria.valid}">
      <span class="icon icon-check">
        <svg><use href="/images/icon_sprite.svg#check" /></svg>
      </span>
      {{ criteriaWording[criteria.name] }}
    </span>
  </div>
</template>

<script>
const config = [
  {
    name: 'minLength',
    regex: /(.){8}/g
  },
  {
    name: 'capitalLetters',
    regex: /([A-ZÄÜÖ])/g
  },
  {
    name: 'lowercaseLetters',
    regex: /([a-zäöü])/g
  },
  {
    name: 'digits',
    regex: /([0-9])/g
  }
]
export default {
  props: {
    value: { // vue2
      type: String,
      required: false,
      default: ''
    },
    modelValue: { // vue3
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['valid'],
  data () {
    return {
      criteriaWording: {
        minLength: 'Mindestens 8 Zeichen',
        capitalLetters: 'Großbuchstaben',
        lowercaseLetters: 'Kleinbuchstaben',
        digits: 'Ziffern'
      }
    }
  },
  computed: {
    passwordCriteria () {
      return config.map(rule => {
        return {
          name: rule.name,
          valid: !!(this.value || this.modelValue)?.match(rule.regex)
        }
      })
    }
  },
  watch: {
    passwordCriteria () {
      this.$emit('valid', Object.values(this.passwordCriteria).every(criteria => criteria.valid))
    }
  }
}
</script>
