<template>
  <a target="_self"
     data-tc="product-anchor"
     class="bx-product__anchor bx-typo--link"
     @click="scrollToAnchor()">
    <strong>Weitere Infos »</strong>
  </a>
</template>

<script>

export default {
  props: {
    anchor: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    scrollToAnchor () {
      const element = document.getElementById(this.anchor)
      if (element) {
        const yOffset = -56
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }
}
</script>
