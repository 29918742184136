<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript v-if="isClient && shouldLoadGTM">
    <iframe :src="`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`"
    title="Google Tag Manager" height="0" width="0" style="display:none;visibility:hidden;"></iframe>
  </noscript>
  <!-- End Google Tag Manager (noscript) -->
</template>

<script>
import { mapState } from 'pinia'

import { useSessionStore } from '../../stores/session'
import { useSourcepointStore } from '../../stores/sourcepoint'
import { useConfigStore } from '../../stores/config'
import clientOnly from '../../mixins/client-only'

export default {
  mixins: [clientOnly],
  data () {
    return {
      scriptInjected: false
    }
  },
  computed: {
    ...mapState(useSourcepointStore, ['consentsExist', 'noScripts']),
    ...mapState(useSessionStore, ['hasPurSub']),
    ...mapState(useConfigStore, ['rsConfig']),
    googleTagManagerId () {
      return this.rsConfig.googleTagManagerId
    },
    shouldLoadGTM () {
      // GTM should be loaded AFTER consent or if PUR sub exists - just dont load it if consent window is active & do not use tag manager on development
      return (this.consentsExist || this.hasPurSub) && !this.noScripts && this.googleTagManagerId && process.env.NODE_ENV !== 'development'
    }
  },
  watch: {
    consentsExist () {
      this.injectScript()
    }
  },
  beforeMount () {
    window.dataLayer = window.dataLayer || []
    this.gtag('consent', 'default', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      security_storage: 'denied',
      personalization_storage: 'denied',
      wait_for_update: 500
    })
    this.gtag('js', new Date())
    this.gtag('config', this.googleTagManagerId)
    this.injectScript()
  },
  methods: {
    gtag (...args) {
      // this is from the official docs  - https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic#tag-manager
      window.dataLayer.push(...args)
    },
    injectScript () {
      if (this.shouldLoadGTM) {
        this.gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          security_storage: 'granted',
          personalization_storage: 'granted'
        })

        /* eslint-disable */
        if (!this.scriptInjected) {

          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', this.rsConfig.googleTagManagerId)

          this.scriptInjected = true
        }
          /* eslint-enable */
      } else {
        // if should not load gtm but we already did (e.g. user removed consent in sourcepoint) we update the datalayer and remove all that shieeeeeeeet
        if (this.scriptInjected) {
          // tell datalayer to remove consent
          this.gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
            security_storage: 'granted',
            personalization_storage: 'granted'
          })
          // remove gtm script
          const script = document.querySelector('script[src*="googletagmanager"]')
          if (script) script.remove()
          // revert render flag
          this.scriptInjected = false
        }
      }
    }
  }
}
</script>
