<template>
  <PageWrapper :page-data="pageData"
               :brand="brandFromStore">
    <template #header-logo>
      <HeaderLogo />
    </template>
    <template #header-user-navi>
      <HeaderUserNavi />
    </template>
    <template #footer>
      <PageFooter :brand="brandFromStore"
                  :footer-data="pageData.footer" />
    </template>
    <template #loading-overlay>
      <LoadingOverlay />
    </template>
  </PageWrapper>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
// These components will be injected into the app via slots
import PageWrapper from '../../components/PageWrapper.vue'
import HeaderUserNavi from '../../components/shared/HeaderUserNavi.vue'
import defaultAppInit from '../../mixins/default-app-init'
import PageFooter from './theme/Footer.vue'
import HeaderLogo from './theme/HeaderLogo.vue'
import LoadingOverlay from './theme/LoadingOverlay.vue'

export default {
  components: {
    HeaderUserNavi,
    PageWrapper,
    HeaderLogo,
    PageFooter,
    LoadingOverlay
  },
  mixins: [defaultAppInit],
  computed: {
    ...mapState(usePageStore, ['pageData', 'brandFromStore'])
  }
}
</script>
