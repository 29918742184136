<template v-if="teasers && teasers.length > 0">
  <div :class="setStyleClasses">
    <div v-if="headline || introductionText"
         class="bx-teaser-container__header">
      <component :is="headlineTagType"
                 v-if="headline"
                 :class="[headlineType, 'bx-teaser-container__header-title', 'bx-typo--teaser-container-header-title']">
        <span class="bx-teaser-container__header-title-text">
          {{ headline }}
        </span>
      </component>
      <div v-if="introductionText"
           class="bx-teaser-container__header-kicker bx-typo--content-continuous-text bx-content__continuous-text bx-typo--teaser-container-header-kicker"
           v-html="introductionText" />
    </div>
    <div v-else
         class="bx-teaser-container__header bx-teaser-container__header--empty" />
    <!-- eslint-disable vue/no-unused-refs MIXIN -->
    <div :id="flickitySliderId"
         ref="flickityDiv"
         :class="{
           'bx-teaser-container__wrapper': !isTeaserSlider,
           'bx-slider': isTeaserSlider,
           'bx-slider--teaser-group': isTeaserSlider,
           'bx-js-hide-buttons': isTeaserSlider
         }">
      <template v-for="(teaser, index) in teasers">
        <component :is="specialBrandTeaser"
                   v-if="specialBrandTeaser && triggerSpecialBrandTeaser(teaser)"
                   :key="`${index}-special`"
                   :composed-teaser="teaser"
                   :include-depth="includeDepth"
                   :list-order-position="listOrderPosition"
                   :teaser-order-position="index"
                   :page-type="pageType"
                   :meta-data="{brand, teaserGroupType}"
                   :data-button-position-logic="isTeaserSlider ? 'image' : false"
                   :class="{'bx-slider__cell': isTeaserSlider}" />
        <Teaser v-else
                :key="index"
                :composed-teaser="teaser"
                :include-depth="includeDepth"
                :list-order-position="dataIsSliderChild ? 10 : listOrderPosition"
                :page-type="pageType"
                :meta-data="{brand, teaserGroupType}"
                :data-button-position-logic="isTeaserSlider ? 'image' : false"
                :class="{'bx-slider__cell': isTeaserSlider}" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import { usePageStore } from '../../stores/page'
import flickitySlider from '../../mixins/flickity-slider'
import adaDynamicSlotMarker from '../../mixins/ada-dynamic-slot-marker'
import Teaser from './Teaser.vue'

export default {
  name: 'TeaserGroup',
  components: {
    Teaser
  },
  mixins: [flickitySlider, adaDynamicSlotMarker],
  props: {
    // Type of teaser group
    teaserGroupType: {
      type: String,
      default: ''
    },
    // Array of teasers
    teasers: {
      type: Array,
      default: () => []
    },
    // Headline of teaser group
    headline: {
      type: String,
      default: ''
    },
    // Type of headline
    headlineType: {
      type: String,
      default: ''
    },
    // Introduction text (kicker) for the teaser group
    introductionText: {
      type: String,
      default: ''
    },
    // Special layout property for highlighting the teaser
    highlighted: {
      type: Boolean,
      default: false
    },
    highlightColor: {
      type: String,
      default: ''
    },
    brand: {
      type: String,
      default: null
    },
    includeDepth: {
      type: Number,
      default: 0
    },
    listOrderPosition: {
      type: Number,
      default: 0
    },
    pageType: {
      type: String,
      default: null
    },
    dataIsSliderChild: {
      type: Boolean,
      default: false
    },
    isSliderOverview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      specialBrandTeaserExists: false,
      isTeaserSlider: this.teaserGroupType.includes('slider')
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['viewportMetaModified']),
    flickitySliderId () {
      return this.isTeaserSlider ? `paragraph-type-teaser-group-flickity-${this.sliderNo}` : null // in vue3 the id field will only be removed if value is null/undefined
    },
    specialBrandTeaser () {
      if (this.rsConfig.dynamicComponents?.teaser?.enabled) {
        return () => import(/* webpackMode: "eager" */`../../apps/${this.brand}/components/theme/BrandTeaser.vue`).then()
      } else {
        return false
      }
    },
    headlineTagType () {
      return this.pageType === 'article' ? 'span' : 'h2'
    },
    teaserGroupSliderConfig () {
      return this.teaserGroupType.includes('slider') ? this.sliderSettings.teaserGroupSlider[this.teaserGroupType] : {}
    },
    setStyleClasses () {
      return {
        'bx-teaser-container': true,
        [this.teaserGroupSliderConfig.teaserGroupType]: this.teaserGroupSliderConfig.teaserGroupType && this.isTeaserSlider,
        [this.teaserGroupType]: true,
        'bx-teaser-container--highlight': this.highlighted,
        [`bx-teaser-container--${this.highlightColor}`]: !!this.highlightColor,
        'bx-teaser-container--special-brand': this.specialBrandTeaserExists,
        'bx-teaser-container--slider-overview': this.isSliderOverview
      }
    }
  },
  async mounted () {
    if (this.isTeaserSlider) {
      await this.initFlickitySlider(this.teaserGroupSliderConfig.customOptions)
    }
  },
  methods: {
    triggerSpecialBrandTeaser (teaser) {
      if (teaser[this.rsConfig.dynamicComponents?.teaser?.triggerProperty]) {
        this.specialBrandTeaserExists = true
        return true
      } else {
        return false
      }
    }
  }
}
</script>
