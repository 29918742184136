<template v-if="items && items.length">
  <div ref="toc"
       class="bx-content bx-table-of-contents bx-typo--table-of-contents bx-js-toc-hide"
       tabindex="0"
       role="button"
       :aria-label="ariaLabel"
       :aria-controls="ariaControls"
       :aria-expanded="tocOpen"
       @click="toggleToc"
       @keyup.enter="toggleToc">
    <div class="bx-table-of-contents__headline">
      <span>{{ title }}</span>
      <svg class="icon icon-arrow_toggle">
        <use href="/images/icon_sprite.svg#arrow_toggle" />
      </svg>
    </div>
    <ul id="bx-aria-toc"
        class="bx-table-of-contents__list">
      <li v-for="(item, index) in items"
          :key="item.href">
        <a class="bx-table-of-contents__links"
           :href="item.href"
           :dataIndex="index"
           v-html="item.text" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => [] // { href: '/link#anchor', text: 'My Link' }
    }
  },
  data () {
    return {
      tocOpen: false,
      viewportWidth: null
    }
  },
  computed: {
    ariaLabel () {
      return this.viewportWidth < 600 ? this.tocOpen ? 'Inhaltsverzeichnis schliessen' : 'Inhaltsverzeichnis öffnen' : false
    },
    ariaControls () {
      return this.viewportWidth < 600 ? 'bx-aria-toc' : false
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  methods: {
    vue3CompatibleBeforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    },
    toggleToc () {
      this.tocOpen = !this.$refs.toc.classList.toggle('bx-js-toc-hide')
    },
    onResize () {
      if (this.viewportWidth !== window.innerWidth) {
        this.viewportWidth = window.innerWidth
      }
    }
  }
}
</script>
