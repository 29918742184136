<template>
  <div class="bx-content bx-series bx-series--navigation bx-typo--series">
    <div :class="setFlickStyles(pageMeta, series)">
      <a v-if="pageMeta.seriesIndex > 0 && pageMeta.seriesIndex+1 < series.length"
         class="bx-series__flick-left"
         :href="isPreview ? '#' : series[pageMeta.seriesIndex-1].url">
        <div class="bx-form__button">
          <Icon name="arrow_series-left" />
          <span class="bx-series__button-text-left">Zurück</span>
        </div>
        <span class="bx-series__link-headline">{{ series[pageMeta.seriesIndex-1].assetDisplayTitle || series[pageMeta.seriesIndex-1].titleLong || series[pageMeta.seriesIndex-1].titleShort }}</span>
      </a>
      <a v-if="pageMeta.seriesIndex+1 < series.length"
         :class="{ 'bx-series__flick-left': pageMeta.seriesIndex === 0, 'bx-series__flick-right': pageMeta.seriesIndex > 0 }"
         :href="isPreview ? '#' : series[pageMeta.seriesIndex+1].url">
        <div class="bx-form__button">
          <span class="bx-series__button-text-right">Weiter</span>
          <Icon name="arrow_series-right" />
        </div>
        <span class="bx-series__link-headline">{{ series[pageMeta.seriesIndex+1].assetDisplayTitle || series[pageMeta.seriesIndex+1].titleLong || series[pageMeta.seriesIndex+1].titleShort }}</span>
      </a>
      <a v-if="pageMeta.seriesIndex+1 === series.length"
         class="bx-series__flick-left"
         :href="isPreview ? '#' : series[0].url">
        <div class="bx-form__button">
          <span class="bx-series__button-text-right">Zur ersten Seite</span>
          <Icon name="arrow_series-right" />
        </div>
        <span class="bx-series__link-headline">{{ series[0].titleLong || series[0].titleShort }}</span>
      </a>
    </div>
    <div class="bx-series__pagination">
      <span>Seite</span>
      <ul class="bx-pager"
          :data-bx-pager-active="pageMeta.seriesIndex+1"
          :data-bx-pager-num="series.length">
        <li v-for="pageNo in paginationItems"
            :key="pageNo"
            :class="addItemStyleClass(pageNo, pageMeta.seriesIndex+1, series.length)">
          <a v-if="series[pageNo-1].assetId !== pageMeta.id"
             :href="isPreview ? '#' : series[pageNo-1].url">
            {{ pageNo }}
          </a>
          <span v-else>
            {{ pageNo }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
import pagination from '../../mixins/pagination'
import Icon from '../shared/Icon.vue'

export default {
  components: { Icon },
  mixins: [pagination],
  props: {
    series: {
      type: Array,
      default: null
    },
    pageMeta: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginationItems: this.createPaginationItems(this.series.length, this.pageMeta.seriesIndex + 1)
    }
  },
  computed: {
    ...mapState(usePageStore, ['isAmp']) // Used by mixin
  },
  mounted () {
    this.setBlockEntriesBasedOnViewport(this.series.length, this.pageMeta.seriesIndex + 1)
  },
  methods: {
    setFlickStyles (pageMeta, series) {
      let styles = 'bx-series__flick-navi bx-form'
      if (pageMeta.seriesIndex > 0 && pageMeta.seriesIndex + 1 < series.length) {
        styles += ' bx-series__flick-navi--in-between'
      }
      return styles
    }
  }
}
</script>
