<template>
  <div class="bx-content bx-social-share bx-typo--social-share">
    <div class="bx-social-share__element-list">
      <div v-if="facebookShareLink"
           class="bx-social-share__element--facebook">
        <a :href="facebookShareLink"
           target="_blank"
           rel="nofollow noopener"
           class="icon icon--social-share icon-facebook">
          <svg><use href="/images/icon_sprite.svg#facebook" /></svg>
          <span class="bx-social-share__text">
            Teilen
          </span>
        </a>
      </div>

      <div v-if="isClient && isIos() && whatsappShareLink"
           class="bx-social-share__element--whatsapp">
        <a :href="whatsappShareLink"
           target="_blank"
           rel="nofollow noopener"
           class="icon icon--social-share icon-whatsapp">
          <svg><use href="/images/icon_sprite.svg#whatsapp" /></svg>
          <span class="bx-social-share__text">
            Teilen
          </span>
        </a>
      </div>

      <PinterestShareButton v-if="pinterestShareLink" />

      <div v-if="flipboardShareLink"
           class="bx-social-share__element--flipboard">
        <a :href="flipboardShareLink"
           target="_blank"
           rel="nofollow noopener"
           class="icon icon--social-share icon-flipboard">
          <svg><use href="/images/icon_sprite.svg#flipboard" /></svg>
          <span class="bx-social-share__text">
            Teilen
          </span>
        </a>
      </div>

      <div v-if="redditShareLink"
           class="bx-social-share__element--reddit">
        <a :href="redditShareLink"
           target="_blank"
           rel="nofollow noopener"
           class="icon icon--social-share icon-reddit">
          <svg><use href="/images/icon_sprite.svg#reddit" /></svg>
          <span class="bx-social-share__text">
            Teilen
          </span>
        </a>
      </div>

      <div v-if="mailtoShareLink"
           class="bx-social-share__element--mailto">
        <a :href="mailtoShareLink"
           target="_blank"
           rel="nofollow noopener"
           class="icon icon--social-share icon-paperplane">
          <svg><use href="/images/icon_sprite.svg#paperplane" /></svg>
          <span class="bx-social-share__text">
            Versenden
          </span>
        </a>
      </div>

      <div v-if="isPrintPreviewEnabled"
           class="bx-social-share__element--print">
        <div class="icon icon--social-share icon-print"
             tabindex="0"
             @click="showPrintPreview()">
          <svg><use href="/images/icon_sprite.svg#print" /></svg>
          <span class="bx-social-share__text">
            Drucken
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'

import { useConfigStore } from '../../stores/config'
import { usePageStore } from '../../stores/page'
import clientOnly from '../../mixins/client-only'
import PinterestShareButton from '../shared/PinterestShareButton.vue'

/**
 *
 * FIND THE LATEST SHARE URL STRUCTURE HERE: https://github.com/bradvin/social-share-urls
 *
 **/

export default {
  components: { PinterestShareButton },
  mixins: [clientOnly],
  props: {
    headline: {
      type: String,
      default: ''
    },
    pageMeta: {
      type: Object,
      default: () => {
        return {}
      }
    },
    pageType: {
      type: String,
      validator: (value) => ['article', 'horoscope'].includes(value),
      default: 'article'
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    socialShareList () {
      return this.rsConfig.socialSettings.socialShare[this.pageType]
    },
    mailtoConfig () {
      return this.rsConfig.socialSettings.mailto
    },
    shareUrl () {
      return this.rsConfig.socialSettings.shareBaseUrl + this.pageMeta.url
    },
    isPrintPreviewEnabled () {
      return this.socialShareList.includes('print')
    },
    pinterestShareLink () {
      return this.socialShareList.includes('pinterest')
    },
    facebookShareLink () {
      return this.socialShareList.includes('facebook') ? 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.shareUrl) : false
    },
    flipboardShareLink () {
      return this.socialShareList.includes('flipboard') ? 'https://share.flipboard.com/bookmarklet/popout?v=2&title=' + encodeURIComponent(this.headline) + '&url=' + encodeURIComponent(this.shareUrl) : false
    },
    whatsappShareLink () {
      return this.socialShareList.includes('whatsapp') ? 'whatsapp://send?text=' + encodeURIComponent(this.shareUrl) : false
    },
    redditShareLink () {
      return this.socialShareList.includes('reddit') ? 'https://reddit.com/submit?url=' + encodeURIComponent(this.shareUrl) + '&title=' + encodeURIComponent(this.headline) : false
    },
    mailtoShareLink () {
      return this.socialShareList.includes('mailto') ? 'mailto:?subject=' + encodeURIComponent(this.mailtoConfig.subject) + '&body=' + encodeURIComponent(this.mailtoConfig.body.replace('%HEADLINE%', this.headline).replace('%URL%', this.shareUrl)) : false
    }
  },
  methods: {
    ...mapActions(usePageStore, ['setPrintPreview']),

    showPrintPreview () {
      document.getElementsByTagName('body')[0].classList.add('bx-page--print')
      document.scrollTop = 0
      scroll(0, 0)

      if (!import.meta.env.SSR && process.env.NODE_ENV !== 'development') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'custom_tracking_print_button'
        })
      }

      this.setPrintPreview(true)
    },
    isIos () {
      return !import.meta.env.SSR ? ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document) : false
    }
  }
}
</script>
