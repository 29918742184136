<template>
  <form class="bx-form bx-form--pur bx-typo--form"
        @submit.prevent="submit">
    <div :class="{ 'bx-has-error': errorMsg.email || showGlobalError,
                   'bx-has-global-error': showGlobalError }">
      <label for="login_email"
             class="bx-form__label">
        E-Mail-Adresse
      </label>
      <input id="login_email"
             v-model="user.email"
             name="email"
             type="text"
             placeholder="Deine E-Mail-Adresse"
             @focusout="clearValidationError('email')">
      <span v-if="errorMsg.email"
            class="bx-form__message bx-form__message--error">
        {{ errorMsg.email }}
      </span>
    </div>
    <div :class="{ 'bx-has-error': errorMsg.password || showGlobalError,
                   'bx-has-global-error': showGlobalError }">
      <label for="login_password"
             class="bx-form__label">
        Passwort
      </label>
      <a href="/passwort-vergessen"
         class="bx-typo--link bx-login__forgot-password">
        Passwort vergessen?
      </a>
      <PasswordField id="login_password"
                     v-model="user.password"
                     placeholder="Dein Passwort"
                     autocomplete="new-password"
                     @input="$emit('input', $event)"
                     @focusout="clearValidationError('password')" />
      <span v-if="errorMsg.password"
            class="bx-form__message bx-form__message--error">
        {{ errorMsg.password }}
      </span>
    </div>

    <div v-if="showGlobalError"
         class="bx-form__message bx-form__message--error">
      Die Anmeldung ist fehlgeschlagen, weil die E-Mail-Adresse oder das Passwort ungültig sind. Bitte probiere es nochmal und achte auf Groß- und Kleinschreibung! Oder hast du dein
      <a href="/passwort-vergessen"
         class="bx-typo--link">
        <strong>Passwort vergessen?</strong>
      </a>
    </div>

    <div>
      <button type="submit"
              class="bx-form__button"
              :disabled="processing || preventSubmit"
              :data-loader="processing">
        Jetzt anmelden
      </button>
    </div>

    <FacebookLogin v-if="isClient && facebookEnabled"
                   label="Mit Facebook anmelden"
                   @facebookLogin="onFacebookLogin" />

    <div class="bx-login__register-now bx-typo--content-continuous-text">
      <p>
        <a href="/registrieren"
           class="bx-typo--link">
          <strong>Noch kein Konto? Jetzt registrieren!</strong>
        </a>
      </p>
    </div>
  </form>
</template>
<script>
import { mapActions, mapState } from 'pinia'

import clientOnly from '../../mixins/client-only'
import { sendGTMEvent } from '../../utils/gtm'
import FacebookLogin from './FacebookLogin.vue'
import PasswordField from './PasswordField.vue'
import { useSessionStore } from '../../stores/session'
import { useNotifyStore } from '../../stores/notify'
import { useConfigStore } from '../../stores/config'

export default {
  components: { PasswordField, FacebookLogin },
  mixins: [clientOnly],
  props: {
    onSuccess: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      preventSubmit: true,
      showGlobalError: false,
      errorMsg: {
        email: null,
        password: null
      },
      user: {
        email: '',
        password: ''
      },
      processing: null
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSessionStore, {
      isLoggedIn: 'isLoggedIn',
      userFromStore: 'user'
    }),
    facebookEnabled () {
      return this.rsConfig.socialSettings?.socialLoginEnabled?.facebook
    }
  },
  created () {
    this.clearForm()
  },
  mounted () {
    // activate the form after the page is loaded
    if (this.isLoggedIn) {
      window.location = '/mein-profil'
    }
    this.preventSubmit = false
  },
  methods: {
    ...mapActions(useSessionStore, ['login', 'facebookLogin']),
    ...mapActions(useNotifyStore, ['showNotification']),
    clearForm () {
      this.user.email = ''
      this.user.password = ''
    },
    clearValidationError (property) {
      this.errorMsg[property] = false
      if (this.showGlobalError) {
        this.showGlobalError = false
      }
    },
    validateFormData () {
      if (this.user.email.length === 0) {
        this.errorMsg.email = 'Bitte trage deine E-Mail-Adresse ein.'
      } else {
        this.errorMsg.email = null
      }

      if (this.user.password.length === 0) {
        this.errorMsg.password = 'Bitte trage dein Passwort ein.'
      } else {
        this.errorMsg.password = null
      }

      this.validationError = Object.values(this.errorMsg).some(message => message)
    },
    async submit () {
      this.processing = true
      try {
        this.showGlobalError = false
        this.validateFormData()
        if (this.validationError) {
          this.processing = null
          this.showNotification({
            type: 'error',
            title: 'Fehler beim Anmelden',
            message: 'Bitte trage deine Zugangsdaten ein.'
          })
          return
        }
        await this.login({ username: this.user.email, password: this.user.password })
        if (this.onSuccess) {
          const gtmEvent = { event: 'login', userId: this.userFromStore?.userId }
          sendGTMEvent(gtmEvent, { userId: undefined })
          this.onSuccess()
        }
      } catch (error) {
        console.error(error)
        this.showGlobalError = true
        this.showNotification({
          type: 'error',
          title: 'Fehler beim Anmelden',
          message: 'Bitte überprüfe deine Eingaben.'
        })
      }
      this.processing = null
    },

    async onFacebookLogin (authResponse) {
      if (!authResponse) { return }
      this.showGlobalError = false
      this.processing = true
      const { accessToken, userID: facebookId } = authResponse
      try {
        if (!accessToken || !facebookId) {
          throw new Error('Facebook access token or id missing')
        }
        try {
          await this.facebookLogin({ accessToken, facebookId })
          if (this.onSuccess) {
            const gtmEvent = { event: 'login', userId: this.userFromStore?.userId }
            sendGTMEvent(gtmEvent, { userId: undefined })
            this.onSuccess()
          }
        } catch (err) {
          if (err.response?.status === 404) {
            this.showNotification({
              type: 'error',
              title: 'Facebook-Anmeldung fehlgeschlagen',
              message: 'Du hast noch kein Facebook-Konto registriert. Weiterleitung zur Registrierungsseite.'
            })

            setTimeout(() => { window.location.href = '/registrieren' }, 5000)
          }
        }
      } catch (error) {
        console.error('Error on Facebook login', error)
        this.showNotification({
          type: 'error',
          title: 'Facebook-Anmeldung fehlgeschlagen',
          message: 'Facebook-Anmeldung fehlgeschlagen'
        })
      } finally {
        this.processing = null
      }
    }
  }
}
</script>
