<template>
  <div class="bx-typo--content-continuous-text">
    <p>
      Deine hinterlegte Rechnungsadresse lautet:
    </p>
    <p>
      <strong>
        {{ customerData.name }}<br>
        {{ customerData.address.line1 }}<br>
        {{ customerData.address.line2 }}<br v-if="customerData.address.line2">
        {{ customerData.address.postal_code }} {{ customerData.address.city }}<br>
        {{ customerData.address.state }}<br v-if="customerData.address.state">
        {{ customerData.address.country }}
      </strong>
    </p>
    <div class="bx-form__item bx-form__item--centered">
      <button class="bx-form__button bx-form__button--secondary"
              @click="showBillingForm">
        Rechnungsadresse ändern
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customerData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    showBillingForm () {
      this.$emit('showBillingForm')
    }
  }
}
</script>
